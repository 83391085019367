import TripSelectionForm from "./Forms/TripSelectionForm";
import TicketOptions from "./TicketOptions/TicketOptions";
import { IRouteConnection } from "../../helpers/models/route.model";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import ApiInstance from "../../helpers/txticket_api";
import { toast } from "react-toastify";
import ApiSessionInstance from "../../helpers/session_api";
import Layout from "../../Layout";
import {
  BookingContext,
  BookingContextType,
} from "../../context/BookingContext";
import VerifiedTicket from "../PurchasedTicket/Tickets/TicketItem/VerifiedTicket";

function Booking() {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);

  const { updateShoppingCartBasket, shoppingCartBasket } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const {
    selectedStations,
    stations,
    selectedDate,
    handleDateSelection,
    handleStationSelection,
    handleTripDetailSubmission,
    isLoadingRoutingDetails,
    isLoadingPricingDetails,

    tickets,
    ticket,
    ticketPrice,
    ticketPrices,
    handleTicketPriceSelection,
    handleTicketSelection,
    passengers,
    handleSetPassenger,
    setTicketsOrder,
    hasConfirmedBooking,
    handleBookingConfirmation,
    isBooking,
    resetTripSelectionBooking,
    isSubmittingBookingDetails,
    setIsBooking,
    setIsTripSelection,
    setIsLoadingRoutingDetails,
    setIsLoadingPricingDetails,
    setTicketPrices,
    ticketsOrder,
    setIsSubmittingBookingDetails,
    addTicketOrder,
    getReservation,
    resetTicketsOrder,
    resetStationDateSelection,
    setStations,
    setUnRefinedStations,
    isTripSelection,
    setIsTicketValid,
    isTicketValid,
  } = React.useContext(BookingContext) as BookingContextType;

  const continueBooking = useCallback(() => {
    toast.dismiss();
    setIsBooking(true);
    setIsTripSelection(false);
    setIsLoadingRoutingDetails(false);
    setIsLoadingPricingDetails(false);
    ApiSessionInstance.clearAwaitingApiResponse();
    ApiSessionInstance.clearConfirmBooking();
  }, [
    setIsBooking,
    setIsLoadingPricingDetails,
    setIsLoadingRoutingDetails,
    setIsTripSelection,
  ]);

  const getPricing = async (route: IRouteConnection) => {
    return new Promise((res, reject) => {
      try {
        ApiInstance.get_products_and_prices(route, (ticketPricesResponse) => {
          console.log("ticketPricesResponse", ticketPricesResponse);
          return res(ticketPricesResponse);
        });
      } catch (error) {
        return reject(error);
      }
    });
  };

  useEffect(() => {
    if(shoppingCartBasket){
      if (shoppingCartBasket.length === 3) {
        toast.warning("You can only purchase 3 tickets at a time", {
          autoClose: 15000,
        });
        navigate("/shopping-cart")
      }
    }
  }, [shoppingCartBasket.length]);

  useEffect(() => {
    if (!isLoadingRoutingDetails && tickets.length) {
      ApiSessionInstance.clearFinishedCheckingAvailableRouting();
      const getPrices = async () => {
        for (let i = 0; i < tickets.length; i++) {
          const route = tickets[i];
          const ticketPricesResponse = await getPricing(route);
          setTicketPrices((prev) => ({
            ...prev,
            [route.wait_time_s]: ticketPricesResponse,
          }));
          if (i === tickets.length - 1) continueBooking();
        }
      };
      getPrices();
    }
  }, [continueBooking, isLoadingRoutingDetails, setTicketPrices, tickets]);

  useEffect(() => {
    if (ApiSessionInstance.getFinishedCheckingAvailableRouting()) {
      ApiSessionInstance.clearFinishedCheckingAvailableRouting();
      if (!isLoadingRoutingDetails && !tickets.length) {
        setIsLoadingRoutingDetails(false);
        setIsLoadingPricingDetails(false);
        toast.dismiss();
        toast.warning(
          `Apologies, but online booking for the selected date is currently unavailable due to train schedule and operational constraints.`,
          { autoClose: 15000 }
        );
      }
    }
  }, [
    isLoadingRoutingDetails,
    tickets,
    isLoadingPricingDetails,
    setIsLoadingRoutingDetails,
    setIsLoadingPricingDetails,
  ]);

  async function handleBookingSubmission() {
    console.log("ticketsOrder", ticketsOrder);
    setIsSubmittingBookingDetails(true);
    const len = shoppingCartBasket.length + ticketsOrder.length;
    if (len > 3) {
      toast.dismiss();
      toast.warning("Ops! You can only purchase 3 tickets at a time", {
        autoClose: 15000,
      });
      setIsSubmittingBookingDetails(false);
      return
    }
    ticketsOrder.map(async (ticketOrder, index) => {
      console.log("index: ", index + 1, "ticketOrder:", ticketOrder);
      try {
        await addTicketOrder(ticketOrder);
        // await getReservation(index + 1);
        await getReservation(ticketsOrder.length + 1);
        const shoppingBasket = ApiInstance.get_shopping_basket();
        if (shoppingBasket) updateShoppingCartBasket(shoppingBasket);
        setIsSubmittingBookingDetails(false);
        resetTicketsOrder();
        resetStationDateSelection();
        navigate("/shopping-cart");
      } catch (error) {
        setIsSubmittingBookingDetails(false);
        console.error(error);
      }
    });
  }

  useEffect(() => {
    if (!isMounted) {
      let stationsResponse = ApiInstance.get_stations();
      if (stationsResponse) {
        const stationList = stationsResponse.filter(
          (station) => station && station !== ""
        );
        setStations(stationList);
        setUnRefinedStations(stationsResponse);
      }
      setIsMounted(true);
    }
  }, [isMounted, setStations, setUnRefinedStations]);

  useEffect(() => {
    // console.log("get_station_name", ApiInstance.get_station_name(3));
    ApiInstance.on_stations_changed = () => {
      let stationsResponse = ApiInstance.get_stations();
      console.log("stations =", stationsResponse);
      if (stationsResponse) {
        const stationList = stationsResponse.filter(
          (station) => station && station !== ""
        );
        setStations(stationList);
        setUnRefinedStations(stationsResponse);
      }
    };
  }, [setStations, setUnRefinedStations]);

  return (
    <Layout>
      {isTripSelection ? (
        <TripSelectionForm
          selectedStations={selectedStations}
          stations={stations}
          selectedDate={selectedDate}
          handleDateSelection={handleDateSelection}
          handleStationSelection={handleStationSelection}
          handleTripDetailSubmission={handleTripDetailSubmission}
          isLoadingBookingDetails={
            isLoadingRoutingDetails || isLoadingPricingDetails
          }
          isBooking={isBooking || !tickets.length}
          onClickNextButton={continueBooking}
        />
      ) : null}
      {isBooking ? (
        <TicketOptions
          selectedStations={selectedStations}
          tickets={tickets}
          ticket={ticket}
          ticketPrice={ticketPrice}
          ticketPrices={ticketPrices}
          selectedDate={selectedDate}
          handleTicketPriceSelection={handleTicketPriceSelection}
          handleTicketSelection={handleTicketSelection}
          passengers={passengers}
          handleSetPassenger={handleSetPassenger}
          handleTicketsOrder={setTicketsOrder}
          hasConfirmedBooking={hasConfirmedBooking}
          handleBookingConfirmation={handleBookingConfirmation}
          isBooking={isBooking}
          resetTripSelectionBooking={resetTripSelectionBooking}
          handleBookingSubmission={handleBookingSubmission}
          isShoppingCartReady={isSubmittingBookingDetails}
        />
      ) : null}
      {isTicketValid && !isBooking ? (
        <VerifiedTicket />
      ): null}
      
    </Layout>
  );
}

export default Booking;
