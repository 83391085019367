import React from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";

const PurchaseHistoryNavigation = ({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/purchase-history" }];
  const orderRoutes = [{path: "/purchase-history/verified"}]
  const matches = matchRoutes(someRoutes, currentLocation);
  const newMatches = matchRoutes(orderRoutes, currentLocation);

  const handleClick = () => {
    navigate("/purchase-history");
    if (onClick) onClick();
  };

  return (
    <React.Fragment>
      <div className="ms-1 header-item-responsive d-sm-flex">
        <button
          type="button"
          className={`btn btn-ghost-primary ${className ? className : ""}`}
          onClick={handleClick}
        >
          <span
            className={`fw-bolder user-name-text fs-16 text-capitalize text-${
              matches || newMatches ? "primary" : "white"
            }`}
          >
            Purchase History
          </span>
        </button>
      </div>
    </React.Fragment>
  );
};

export default PurchaseHistoryNavigation;
