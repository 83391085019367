import { Button, Col, Modal } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Tickets from "./Tickets/Tickets";
import React, { Fragment, useContext, useEffect, useState } from "react";
import SmallScreenPurchasedTicket from "./SmallScreen";
import Layout from "../../Layout";
import html2canvas from "html2canvas";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import ApiSessionInstance from "../../helpers/session_api";
import { toast } from "react-toastify";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";

interface IPurchasedTicketProps {
  children?: React.ReactNode; // For reusability on buy page
}

const PurchasedTicket = (props: IPurchasedTicketProps) => {
  const navigate = useNavigate();
  const [nextTicket, setNextTicket] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState<boolean>(
    !!ApiSessionInstance.getDownloadTickets(),
  );
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/purchased-ticket/:purchaseId" }];
  const matches = matchRoutes(someRoutes, currentLocation);

  const { purchasedTickets, buyerDetails } = useContext(
    GlobalContext,
  ) as GlobalContextType;
  // const { buyerDetails, user } = React.useContext(
  //   GlobalContext,
  // ) as GlobalContextType;

  const isEmpty = true;
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const onClickNextButton = () => {
    if (purchasedTickets.length > nextTicket + 1) {
      setNextTicket((prev) => prev + 1);
    }
  };

  const onClickBackButton = () => {
    if (nextTicket > 0) {
      setNextTicket((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (!purchasedTickets.length) {
      toast.warning("Please select ticket");
      navigate("/purchase-history");
    }
  }, [navigate, purchasedTickets.length]);

  const onDownload = async () => {
    if (
      // ApiSessionInstance.getDownloadTickets() &&
      purchasedTickets &&
      Object.keys(purchasedTickets[0] || {}).length
    ) {
      let doc = document.querySelector<HTMLDivElement>(".downloadable-ticket");
      if (doc) {
        // setIsDownloading(true);
        ApiSessionInstance.clearDownloadTickets();
        const ticket = purchasedTickets[nextTicket];
        // purchasedTickets.map(async (purchasedTicket) => {
        const stations = `${ticket.stid1_name}-${ticket.stid2_name}`;
        const ticketId = ticket.tno;
        // reassignment for multiple tickets
        doc = document.querySelector<HTMLDivElement>(".downloadable-ticket");
        if (!doc) return;
        const canvas = await html2canvas(doc, {
          allowTaint: false,
          scale: 2,
        });
        canvas.id = `${ticketId}`;
        // .then(function (canvas) {
        document.body.appendChild(canvas);

        const image = canvas.toDataURL("image/jpeg", 1.0);
        const link = document.createElement("a");

        // const stations = `${purchasedTicket.stid1_name}-${purchasedTicket.stid2_name}`;
        // const ticketId = purchasedTicket.tno;
        link.download = `my-ticket-${stations}-${ticket.inception}-${ticketId}.jpeg`;
        link.href = image;
        link.click();

        // if (purchasedTickets.length > nextTicket + 1)
        //   setNextTicket((prev) => prev + 1);
        // });
        document.body.removeChild(canvas);
        return ticket;
        // });
        // setIsDownloading(false);
        // setNextTicket(0);
      }
    }
  };

  // console.log("this is the next ticket",nextTicket);
  

  if (isLargeScreen) {
    return (
      <Layout>
        <Modal
          contentClassName={`backgroundDropdown px-2 px-lg-5 py-4 rounded-4 ${
            isDownloading ? "" : "rounded-4 border border-primary"
          }`}
          // } downloadable-ticket`}
          backdropClassName="bg-transparnt"
          show={!!matches}
          size="xl"
          // centered
          // style={{marginTop: "-5px"}}
        >
          {/* <h1
            className="text-capitalize text-primary text-center"
            style={{
              marginTop: "-20px",
              fontWeight: "520",
              letterSpacing: "0.8rem",
            }}
          >
            YOUR TICKET
          </h1> */}
           <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "40%",
                margin: "auto",
                marginBottom:"10px"
              }}
            >
              <Button
              className={`${purchasedTickets.length >1 && nextTicket > 0 ? "text-primary border border-primary rounded-4" : "button-color rounded-4"}`}
                // className="text-primary border border-primary rounded-3"
                variant="transperent"
                style={{ padding: "20px 30px" }}
                onClick={onClickBackButton}
              >
                <FaLessThan className="fs-25" style={{ fontWeight: "600" }} />
              </Button>
              <Button
                onClick={onDownload}
                className="fs-20 text-dark px-5"
                style={{ fontWeight: "600", display:"block" }}
              >
                <div>Download</div>
                <div className="fs-12"> Ticket {nextTicket + 1} out of {purchasedTickets.length}</div>
                
              </Button>
              <Button
                className={`${purchasedTickets.length >1 && nextTicket !== 2 ? "text-primary border border-primary rounded-4" : "button-color rounded-4"}`}
                variant="transperent"
                style={{ padding: "20px 30px" }}
                onClick={onClickNextButton}
              >
                {" "}
                <FaGreaterThan
                  className="fs-25"
                  style={{ fontWeight: "600" }}
                />{" "}
              </Button>
            </div>
          <Button
            onClick={() => navigate("/purchase-history")}
            variant="white"
            className="text-white position-absolute end-0 mx-3"
            style={{ marginTop: "-30px" }}
          >
            <i className="bi bi-x display-3" />
          </Button>
          <Col
            className="rounded-4"
            style={{ width: "40%", margin: "auto", background: "white" }}
          >
            <Tickets
              onClickNextButton={onClickNextButton}
              onClickBackButton={onClickBackButton}
              nextTicket={nextTicket}
              isDownloading={isDownloading}
            />
            <i className="fas fa-less-than display-3"></i>
          </Col>
          {/* <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "40%",
                margin: "auto",
                marginTop:"10px"
              }}
            >
              <Button
                className="text-primary border border-primary rounded-3"
                variant="transperent"
                style={{ padding: "15px 30px" }}
                onClick={onClickBackButton}
              >
                <FaLessThan className="fs-25" style={{ fontWeight: "600" }} />
              </Button>
              <Button
                onClick={onDownload}
                className="fs-20 text-dark px-5"
                style={{ fontWeight: "600", display:"block" }}
              >
                <div>Download</div>
                <div className="fs-12"> Ticket {nextTicket + 1} out of {purchasedTickets.length}</div>
                
              </Button>
              <Button
                className="text-primary border border-primary"
                variant="transperent"
                style={{ padding: "15px 30px" }}
                onClick={onClickNextButton}
              >
                {" "}
                <FaGreaterThan
                  className="fs-25"
                  style={{ fontWeight: "600" }}
                />{" "}
              </Button>
            </div>
          </div> */}
        </Modal>
      </Layout>
    );
  }

  const contentClass = isEmpty ? "" : "";
  return (
    <Layout>
      <Modal
        // contentClassName={`bg-transparent ${contentClass} downloadable-ticket`}
        contentClassName={`bg-transparent ${contentClass}`}
        backdropClassName="bg-transparent"
        show={!!matches}
        size="xl"
        centered={isMediumScreen}
      >
        <SmallScreenPurchasedTicket
          onClickNextButton={onClickNextButton}
          onClickBackButton={onClickBackButton}
          nextTicket={nextTicket}
          noOfItems={purchasedTickets.length}
          isDownloading={isDownloading}
          onDownload={onDownload}
          {...props}
        />
      </Modal>
    </Layout>
  );
};

export default PurchasedTicket;
