import { Button, Col } from "react-bootstrap";
import Tickets from "./Tickets/Tickets";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import logosm from "../../assets/images/logo-sm.png";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { IPurchasedTicket } from "../../helpers/models/txticket_api.model";
import { useContext } from "react";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import { useMediaQuery } from "react-responsive";

interface PurchasedTicketProps {
  nextTicket: number;
  noOfItems: number;
  isDownloading: boolean;
  onClickNextButton: () => void;
  onClickBackButton: () => void;
  isEmpty?: boolean;
  children?: React.ReactNode; // For reusability on buy page
  onDownload: () => Promise<IPurchasedTicket | undefined>;
}

const SmallScreenPurchasedTicket = ({
  isEmpty,
  noOfItems,
  onDownload,
  onClickNextButton,
  onClickBackButton,
  nextTicket,
  isDownloading,
}: PurchasedTicketProps) => {
  const navigate = useNavigate();
  const { buyerDetails } = useContext(GlobalContext) as GlobalContextType;
  const isSmallScreen = useMediaQuery({
    query:"(max-width: 330px)"
  })

  const startTextClass = "text-start";
  const generalClass = `p-2 ${
    // isDownloading ? "" : "rounded-4 border border-primary"
    isDownloading ? "" : ""
  }`;
  const colClass = isEmpty ? startTextClass : generalClass;
  // const bg = "linear-gradient(#000,hsla(0,0%,100%,.091))";
  const bg = "#ffff";
  // const colStyleAttribute = isEmpty ? {} : { background: "none" };
  return (
    <Col
      style={{ backgroundColor: "transparent !important", margin: isSmallScreen? "none" : "auto" }}
      md={12}
      className={` ${colClass}`}
    >
      <Button
        onClick={() => navigate("/purchase-history")}
        variant="white"
        className="text-white position-absolute p-0 end-0 mx-3 top-0 rounded-3"
        // className="text-white position-absolute p-0 end-0  top-0"
        style={{ marginTop: "40px", border: "1px solid #00f600" }}
      >
        <h2 className="text-white " style={{ padding: "5px 15px 1px" }}>
          X
        </h2>
      </Button>
      <div
        style={{
          background: bg,
          width: "90%",
          margin: "auto",
          marginTop: "76px",
          borderRadius: "10px",
        }}
      >
        
        <Tickets
          onClickNextButton={onClickNextButton}
          onClickBackButton={onClickBackButton}
          isDownloading={isDownloading}
          nextTicket={nextTicket}
        />
      </div>
      <div className="w-100">
        <h3 className={`text-center text-primary mt-2 ${isSmallScreen? "fs-16" : ""}`}>Ticket {nextTicket + 1} out of {noOfItems}</h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            margin: "auto",
            marginBottom: isSmallScreen? "20px" : ""
            // marginLeft: isSmallScreen? "-10px" : "",
          }}
        >
          <Button
            className="text-primary border border-primary rounded-3"
            variant="transperent"
            style={{ padding: isSmallScreen? "10px 30px" : "15px 30px" }}
            onClick={onClickBackButton}
          >
            <FaLessThan className={`${isSmallScreen? "fs-18" : "fs-25"}`} style={{ fontWeight: "600" }} />
          </Button>
          <Button onClick={onDownload} className={`${isSmallScreen? "fs-18" : "fs-25"} text-dark`} style={{ fontWeight: "600" }}>
            Download
          </Button>
          <Button
            className="text-primary border border-primary"
            variant="transperent"
            style={{ padding: isSmallScreen? "10px 30px" : "15px 30px" }}
            onClick={onClickNextButton}
          >
            {" "}
            <FaGreaterThan
              className={`${isSmallScreen? "fs-18" : "fs-25"}`}
              style={{ fontWeight: "600" }}
            />{" "}
          </Button>
        </div>
      </div>
      
    </Col>
  );
};

export default SmallScreenPurchasedTicket;
