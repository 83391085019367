import { Button, Col } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import GoButton from "../../../component/GoButton/GoButton";
import OrderHeading from "../OrderHeading/OrderHeading";
import { IShoppingBasketData } from "../../../helpers/models/txticket_api.model";
import Order from "./Order";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
// import ApiSessionInstance from "../../../helpers/session_api";

interface OrdersProps {
  shoppingCartBasket: IShoppingBasketData[];
  children?: React.ReactNode; // For reusability on buy page
  title?: string;
}

const Orders = ({ shoppingCartBasket, children, title }: OrdersProps) => {
  const [cartTotalPrice, setCartTotalPrice] = useState<number>(0);
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/buy" }];
  const matches = matchRoutes(someRoutes, currentLocation);

  const addNewReservation = () => {
     if(shoppingCartBasket.length === 3){
      toast.warning("You can only buy three tickets at once. Please click 'GO' to proceed with payment, or click 'bin' to remove tickets.", {
        autoClose: 5000,
      });
    }else{
      const timeOut = setTimeout(() => {
        if (shoppingCartBasket.length < 3) {
          toast.success("Make new reservation.");
        }
        clearTimeout(timeOut);
      }, 500);
      navigate("/");
    }

  };
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isSmallScreen = useMediaQuery({
    query:"(max-width:330px)"
  })

  const adjustScreen = isSmallScreen? "fs-12" : "fs-16"

  // const onPurchaseWithoutRegistration = () => {
  //   navigate("/continue-without-registration");
  //   ApiSessionInstance.clearConfirmBuyerDetails();
  // };

  useEffect(() => {
    let totalPrice = 0;
    shoppingCartBasket.map((sc) => {
      totalPrice += sc.centprice;
      return sc.route.leg[0].lxdate + sc.route.stidarr + sc.route.stiddep + sc.route.leg[0].tod_dep;
    });
    setCartTotalPrice(totalPrice);
  }, [shoppingCartBasket]);

  return (
    <Fragment>
      <Col md={12} sm={9} className={`${isMediumScreen? "mt-2" : "mt-4"} fs-14  mx-auto`} style={{marginBottom: isMediumScreen ? "" : "-15px", height: "10%"}}>
        <p
          className="text-white-50 text-center d-flex justify-content-between mx-auto"
          style={{ maxWidth: "300px" }}
        >
          <span className="text-white">Booking</span>
          <span className="text-white">&gt;</span>
          <span className="text-white">Shopping Cart</span>
          {/* <span className={`${matches ? "text-white" : ""}`}>&gt;</span> */}
          <span className="text-white">&gt;</span>
          <span className={`${matches ? "text-white" : ""}`}> Buy </span>
          <span>&gt;</span>
          <span> Ticket </span>
        </p>
      </Col>

      <Col
        // style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
        // height: "290px"
        md={9}
        lg={12}
        xl={9}
        className={`${shoppingCartBasket.length === 1 ? "mb-5" : ""} m-auto border border-primary rounded-4 p-2 px-md-3 ${ shoppingCartBasket.length === 1 ?'pt-md-5' : "pt-md-5"}`}
        style={{ height : "90%", paddingBottom: shoppingCartBasket.length === 1 ? "50px" : "50px" }}
      >
        <div className="d-md-none d-block position-relative">
          <h1 className="text-capitalize text-primary text-center pb-3" style={{ fontSize: isSmallScreen? "15px" : "", fontWeight: isSmallScreen? "600" : ""}}>
            {title || "Shopping cart"}
          </h1>
          <div className="position-absolute top-0 end-0 p-2">
            <Button
              variant="outline"
              onClick={() => navigate("/")}
              className="p-0 border border-primary rounded-circle text-white"
              style={{
                transform: "scale(1.3)",
                width: "30px",
              }}
            >
              <i className="bi bi-plus" />
            </Button>
          </div>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{
            marginTop: isMediumScreen ? "" : "-15px",
            marginBottom: isMediumScreen ? "" : "-10px",
          }}
        >
          <h6
            className={`${adjustScreen} text-primary text-capitalize m-0 px-2`}
            style={{ letterSpacing: "2.1px", fontWeight: 700 }}
          >
            Current Booking{" "}
            <span
              className="d-inline-block badge badge-primary"
              style={{ backgroundColor: "none", border: "1px solid #00f600" }}
            >
              {shoppingCartBasket.length}
            </span>
          </h6>
          <Button
            variant="outline"
            onClick={addNewReservation}
            className="p-0 border border-primary rounded-circle text-white d-md-block d-none"
            style={{ transform: "scale(1.4", width: "30px" }}
          >
            <i className="bi bi-plus" />
          </Button>
        </div>
        <div className="table-responsive">
          <OrderHeading />
          <Order shoppingCartBasket={shoppingCartBasket}  Shoplength={shoppingCartBasket.length}/>
        </div>
        {!children &&
          // <Fragment>
          //   <Col
          //     md={9}
          //     lg={6}
          //     className="m-auto px-2 px-md-3 d-block d-md-none"
          //   >
          //     <p
          //       style={{ fontWeight: 700 }}
          //       className="text-primary fs-14 text-uppercase text-center mb-0"
          //     >
          //       Continue purchase
          //     </p>
          //     {/* <div className="d-flex flex-column justify-content-between mb-3"> */}
          //     <div className="d-flex flex-column justify-content-between">
          //       <Button
          //         style={{ fontWeight: 700 }}
          //         variant="primary rounded-4 text-dark"
          //         className="btn-sm mb-2"
          //         onClick={() => navigate("/continue-without-registration")}
          //       >
          //         without registering
          //       </Button>
          //       <Button
          //         style={{ fontWeight: 700 }}
          //         variant="primary rounded-4 text-dark"
          //         className="btn-sm"
          //       >
          //         with an account
          //       </Button>
          //     </div>
          //   </Col>
          // </Fragment>
          ""}
      </Col>
      <Col
        md={9}
        lg={12}
        xl={9}
        className="m-auto px-2 px-md-3 d-none d-md-block"
      >
        <p className="text-white fs-18 mt-4" style={{ fontWeight: 700, marginBottom: "-25px", marginTop:"40px"}}>
          Total Price:  ₦{" "}
          {(cartTotalPrice / 100).toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
          })}.00
        </p>
      </Col>
      {children ? (
        children
      ) : (
        <Fragment>
          {/* <Col md={9} lg={6} className="m-auto px-2 px-md-3 d-none d-md-block">
            <p
              style={{ fontWeight: 700 }}
              className="text-primary fs-14 text-uppercase text-center"
            >
              Continue purchase
            </p>
            <div className="d-flex justify-content-between mb-3">
              <Button
                style={{ fontWeight: 700 }}
                variant="primary rounded-4 text-dark"
                onClick={onPurchaseWithoutRegistration}
              >
                without registering
              </Button>
              <Button
                style={{ fontWeight: 700 }}
                variant="primary rounded-4 text-dark"
              >
                with an account
              </Button>
            </div>
          </Col> */}
          
          <GoButton
            disabled={false}
            onClick={() => navigate("/continue-without-registration")}
            disabledNextButton
            // isLoading
            onClickNextButton={() => {}}
            onClickBackButton={() => {}}
            disabledBackButton
            showGroupButtons
          />
          <Col md={9} lg={12} xl={9} className="m-auto px-2 d-block d-md-none">
            <div className="d-none d-md-block">
              {" "}
              <p className="text-white fs-14 mt-3">
                Total Price:  ₦{" "}
                {(cartTotalPrice / 100).toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
            <div className="d-block d-md-none">
              {" "}
              <p className="text-white fs-16" style={{ fontWeight: 700, marginTop:isSmallScreen? "-20px" : "0"}}>
                Total Price:  ₦{" "}
                {(cartTotalPrice / 100).toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })}
              </p>
            </div>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Orders;
