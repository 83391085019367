import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TicketHeading from "../TicketHeading/TicketHeading";
import { useContext, useEffect } from "react";
import Ticket from "./Ticket";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../context/GlobalContext";
import moment from "moment";
import logosm from "../../../assets/images/logo-sm.png";
import lock1 from "../../../assets/images/lock.svg";
// import lock1 from "../../../assets/images/consumed.svg";
import validIcon from "../../../assets/images/valid.svg";
import { BookingContext, BookingContextType } from "../../../context/BookingContext";

interface TicketsProps {
  nextTicket: number;
  isDownloading: boolean;
  onClickNextButton: () => void;
  onClickBackButton: () => void;
}
const Tickets = ({ isDownloading, nextTicket }: TicketsProps) => {
  const navigate = useNavigate();
  // const [nextTicket, setNextTicket] = useState<number>(0);

  const { purchasedTickets, setViewQrcode } = useContext(
    GlobalContext,
  ) as GlobalContextType;

  const {setIsTripSelection, setIsBooking, setIsTicketValid} = useContext(BookingContext)as BookingContextType;

  
  // const onClickNextButton = () => {
    //   if (purchasedTickets.length > nextTicket + 1) {
      //     setNextTicket((prev) => prev + 1);
      //   }
      // };
      
      // const onClickBackButton = () => {
        //   if (nextTicket > 0) {
          //     setNextTicket((prev) => prev - 1);
          //   }
          // };
          
          const handleNavigate = (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            navigate("/purchased-ticket/1/locked");
          };
          useEffect(() => {
    if (
      purchasedTickets &&
      purchasedTickets.length > 0 &&
      purchasedTickets[nextTicket] &&
      purchasedTickets[nextTicket].qrcode
    ) {
      setViewQrcode(purchasedTickets[nextTicket].qrcode);
    }
  }, [nextTicket, purchasedTickets, setViewQrcode]);
  
  if (
    !purchasedTickets ||
    !purchasedTickets[nextTicket] ||
    purchasedTickets.length === 0
  ) {
    return null;
  }
  
  const currentTicket = purchasedTickets[nextTicket];
  const newData = currentTicket.ttlist[0].text.split(";")
  const fulName = `${newData[1]} ${newData[0]}`
  return purchasedTickets[nextTicket] ? (
    <div className="downloadable-ticket ">
      <div
        className="d-flex justify-content-between mt-2 p-1 d-none d-md-flex"
        style={{ alignItems: "center" }}
      >
        <div>
          {" "}
          <img src={logosm} alt="" height="55" />
        </div>
        <h4
          className="text-capitalize text-center fs-45"
          style={{
            fontWeight: "500",
            color: "#017100",
            letterSpacing: "0.5rem",
          }}
        >
          NRC
        </h4>
        <div className="fs-12">
          https:// <br />
          nrc.gov.ng
        </div>
      </div>
      <div className="d-block d-md-none">
        <div
          className="d-flex justify-content-between mt-2 p-1 mb-0"
          style={{ alignItems: "center" }}
        >
          <div>
            {" "}
            <img src={logosm} alt="" height="40" />
          </div>
          <h4
            className="text-capitalize text-center fs-40"
            style={{
              fontWeight: "500",
              color: "#017100",
              letterSpacing: "0.5rem",
            }}
          >
            NRC
          </h4>
          <div className="fs-10">
            https:// <br />
            nrc.gov.ng
          </div>
        </div>
        <div
          className="text-center fs-12 mb-2 text-capitalize"
          style={{ fontWeight: "600", letterSpacing: "0.2rem" }}
        >
          {/* Ticket for {(currentTicket.ttlist[0].text || "").split(";").join(" ")} */}
          Ticket for {fulName}
        </div>
      </div>
      <div
        className="text-center fs-17 mb-3 text-capitalize d-none d-md-block"
        style={{ fontWeight: "600", letterSpacing: "0.2rem" }}
      >
        Ticket for {fulName}
      </div>
      <div className="">
        {/* <div className="position-relative w-100 d-flex d-sm-none text-light-emphasis justify-content-center mb-2">
          {!isDownloading && (
            <p
              className="position-absolute w-100 text-center fs-12"
              style={{
                top: "49%",
                left: "1rem",
                transform: "translateX(-50%) translateY(-50%) rotate(-90deg)",
                fontWeight: "600",
                color: "#017100",
              }}
            >
              Click the QR-code to lock.
            </p>
          )}

          <div
            style={{
              minWidth: "200px",
              maxWidth: "75%",
              border: "2px solid black",
            }}
            className="mx-auto w-80  rounded-4 p-1"
            onClick={() => navigate("/purchased-ticket/1/locked")}
            dangerouslySetInnerHTML={{
              __html: purchasedTickets[nextTicket].qrcode,
            }}
          ></div>
          <div
            className="text-dark fs-12"
            style={{
              fontWeight: "600",
              position: "absolute",
              marginTop: "187px",
              backgroundColor: "white",
              marginLeft: "-50px",
              padding: "2px 5px",
            }}
          >
            TXticket code
          </div>
          
          {!isDownloading && (
            <p
              className="position-absolute w-100 text-center m-0 fs-12"
              style={{
                top: "43%",
                right: "1rem",
                transform: "translateX(50%) translateY(50%) rotate(90deg)",
                fontWeight: "600",
                color: "#017100",
              }}
            >
              Click the QR-code to lock.
            </p>
          )}
        </div> */}
        {/* <img
          style={{ maxWidth: "85%" }}
          className="mx-auto w-100"
          onClick={() => navigate("/purchased-ticket/1/locked")}
          src={qrpics}
          alt="qrpics"
        /> */}
        <Row
          className="d-flex d-sm-none mb-1"
          style={{ height: "70%", marginTop: "-5px" }}
        >
          {!isDownloading && (
            <div className="d-flex w-100 gap-2">
              <div style={{ width: "18%", marginLeft: "5px" }}>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={lock1}
                    alt="info"
                    style={{ width: "100%", cursor: "pointer" }}
                    onClick={handleNavigate}
                  />
                </div>
                {/* <div
                  style={{
                    border: "2px solid green",
                    borderRadius: "10%",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={infoLogo}
                    alt="info-logo"
                    style={{ width: "100%" }}
                  />
                </div> */}
              </div>
              <div>
                <div className="" style={{ width: "60%" }}>
                  <div
                    style={{
                      width: "190px",
                      border: "2px solid black",
                      padding: "5px",
                      height: "90%",
                    }}
                    className="rounded-4"
                    onClick={handleNavigate}
                    dangerouslySetInnerHTML={{
                      __html: currentTicket.qrcode,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      backgroundColor: "white",
                      marginTop: "-14px",
                      marginLeft: "35px",
                      padding: "2px",
                      fontSize: "12px",
                    }}
                  >
                    TXticket code
                  </div>
                </div>
              </div>
              <div className="" style={{ width: "18%", marginRight: "5px" }} onClick={()=>{
                navigate("/purchase-history/verified")
                setIsBooking(false)
                setIsTicketValid(true)
                setIsTripSelection(false)
              }}>
                {" "}
                <img
                  src={validIcon}
                  alt="img"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </div>
            </div>
          )}
        </Row>

        {/* <Row className="d-none d-sm-flex">
          {!isDownloading && (
            <div>
              <div className="border border-primary">
                <img src={lock1} alt="svg" style={{ width: "40px" }} />
              </div>
            </div>
          )}
          <Col sm={1} className="position-relative">
            <p className="opacity-0">screen</p>
          </Col>
          <Col sm={10}>
            <div
              style={{
                minWidth: "110px",
                border: "2px solid black",
                padding: "5px",
              }}
              className="mx-auto col-9 w-90 rounded-4 m-2"
              onClick={() => navigate("/purchased-ticket/1/locked")}
              dangerouslySetInnerHTML={{
                __html: purchasedTickets[nextTicket].qrcode,
              }}
            ></div>
            <div
              className="text-dark fs-13"
              style={{
                fontWeight: "600",
                position: "absolute",
                marginTop: "-22px",
                backgroundColor: "white",
                marginLeft: "80px",
                padding: "2px 5px",
              }}
            >
              TXticket code
            </div>
          </Col>
          {!isDownloading && (
            <p
              className="position-absolute w-100 text-center m-0 fs-18"
              style={{
                top: "32%",
                right: "23.6rem",
                transform: "translateX(50%) translateY(50%) rotate(90deg)",
                letterSpacing: "0.3rem",
                fontWeight: "500",
                color: "#017100",
              }}
            >
              Enjoy your journey12
            </p>
          )}

          <Col sm={1} className="position-relative">
            <p className="opacity-0">screen</p>
          </Col>
        </Row> */}

        <Row className="d-none d-sm-flex mb-4" style={{ height: "70%" }}>
          {!isDownloading && (
            <div className="d-flex w-100 gap-2">
              <div style={{ width: "20%", marginLeft: "5px" }}>
                <div
                  style={{ cursor:"pointer"  }} onClick={handleNavigate}
                >
                  <img src={lock1}alt="" style={{ width: "100%" }} />
                </div>
                {/* <div
                  style={{
                    border: "2px solid green",
                    borderRadius: "10%",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={infoLogo}
                    alt="info-logo"
                    style={{ width: "100%" }}
                  />
                </div> */}
              </div>
              <div>
                <div className="" style={{ width: "70%" }}>
                  <div
                    style={{
                      width: "230px",
                      border: "2px solid black",
                      padding: "5px",
                      height: "100%",
                    }}
                    className="rounded-4"
                    onClick={handleNavigate}
                    dangerouslySetInnerHTML={{
                      __html: currentTicket.qrcode,
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      backgroundColor: "white",
                      marginTop: "-17px",
                      marginLeft: "35px",
                      padding: "5px",
                    }}
                  >
                    TXticket code
                  </div>
                </div>
              </div>
              <div className="" style={{ width: "22%", marginRight: "5px" }} onClick={()=>{
                navigate("/purchase-history/verified")
                setIsBooking(false)
                setIsTicketValid(true)
                setIsTripSelection(false)
              }}>
                {" "}
                <img
                  src={validIcon}
                  alt="img"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </div>
            </div>
          )}
        </Row>
        {/* </div> */}
        <div className="" style={{width:'80%', margin:"auto"}}>
          <TicketHeading />
          {purchasedTickets.length ? (
            <Ticket purchasedTicket={[currentTicket]} />
          ) : null}
        </div>

        <div
          className="col-10 mx-auto text-dark d-block d-sm-none text-start rounded-3"
          style={{
            paddingLeft: "36px",
            paddingTop: "4px",
            paddingBottom: "4px",
            fontWeight: "500",
            marginTop: "-20px",
            border: "1.5px solid #017100",
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: "40px" }}>
            <p className="m-0 fs-11">Number:</p>
            <p className="m-0 fs-11" style={{ textAlign: "left" }}>
              {String(currentTicket.tno).replace(
                /^(\d{3})(\d{3})(\d{4})/,
                "$1-$2-$3",
              )}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "58px" }}>
            <p className="m-0 fs-11">Code:</p>
            <p className="m-0 fs-11" style={{ textAlign: "left" }}>
              {String(currentTicket.tnocodechar).replace(
                /^(\w{3})(\w{3})/,
                "$1-$2",
              )}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "25px" }}>
            <p className="m-0 fs-11">Verification:</p>
            <p className="m-0 fs-11" style={{ textAlign: "left" }}>
              {String(currentTicket.tnocode).replace(
                /^(\w{3})(\w{3})/,
                "$1-$2",
              )}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "29px" }}>
            <p className="m-0 fs-11">Purchased:</p>
            <p className="m-0 fs-11" style={{ textAlign: "left" }}>
              {moment(currentTicket.time_created).format("D.M.YYYY")}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "61px" }}>
            <p className="m-0 fs-11">Valid:</p>
            <p className="m-0 fs-11" style={{ textAlign: "left" }}>
              {moment(currentTicket.time_created).format("D.M.YYYY")},{" "}
              {currentTicket.rvlist[0].tod1}
            </p>
          </div>
        </div>
        <div
          className="col-10 mx-auto d-none d-sm-block text-dark text-start fs-12 rounded-3"
          style={{
            paddingLeft: "61px",
            paddingTop: "4px",
            paddingBottom: "4px",
            fontWeight: "500",
            marginTop: "-15px",
            border: "1.5px solid #017100",
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: "43px" }}>
            <p className="m-0 ">Number:</p>
            <p className="m-0" style={{ textAlign: "left" }}>
              {String(currentTicket.tno).replace(
                /^(\d{3})(\d{3})(\d{4})/,
                "$1-$2-$3",
              )}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "63px" }}>
            <p className="m-0">Code:</p>
            <p className="m-0" style={{ textAlign: "left" }}>
              {String(currentTicket.tnocodechar).replace(
                /^(\w{3})(\w{3})/,
                "$1-$2",
              )}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "27px" }}>
            <p className="m-0">Verification:</p>
            <p className="m-0" style={{ textAlign: "left" }}>
              {String(currentTicket.tnocode).replace(
                /^(\w{3})(\w{3})/,
                "$1-$2",
              )}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "31px" }}>
            <p className="m-0">Purchased:</p>
            <p className="m-0" style={{ textAlign: "left" }}>
              {moment(currentTicket.time_created).format("D.M.YYYY")}
            </p>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "66px" }}>
            <p className="m-0">Valid:</p>
            <p className="m-0" style={{ textAlign: "left" }}>
              {moment(currentTicket.time_created).format("D.M.YYYY")},{" "}
              {currentTicket.rvlist[0].tod1}
            </p>
          </div>
        </div>
        <div className="col-11 mx-auto mt-2 d-none d-sm-block">
          <p
            className="fs-14 text-center"
            style={{ color: "#017100", marginBottom: "4px" }}
          >
            Only tickets obtained via the NRC app, on-board or at the train
            stations counters are guaranteed to be valid!
          </p>
        </div>
        <div className="col-11 mx-auto mt-2 d-block d-sm-none">
          <p
            className="fs-11 text-center"
            style={{ color: "#017100", paddingBottom: "6px" }}
          >
            Only tickets obtained via the NRC app, on-board or at the train
            stations counters are guaranteed to be valid!
          </p>
        </div>
      </div>
    </div>
  ) : null;
};

export default Tickets;
