import { Button, Modal } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Orders from "./Orders/Orders";
import React, { useEffect, useState } from "react";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import ApiInstance from "../../helpers/txticket_api";
import SmallScreenPurchaseHistory from "./SmallScreen";
import ApiSessionInstance from "../../helpers/session_api";
import TextsComponent from "../../component/TextsComponent/TextsComponent";
import Layout from "../../Layout";

interface IPurchaseHistoryProps {
  children?: React.ReactNode; // For reusability on buy page
  show?: boolean;
}

const PurchaseHistory = (props: IPurchaseHistoryProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [isMounted, setIsMounted] = useState(false);
  const someRoutes = [{ path: "/purchase-history" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const isLargeScreenBelow = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const {
    setTicketList,
    ticketList,
    lastPurchaseTicketList,
    setLastPurchaseTicketList,
  } = React.useContext(GlobalContext) as GlobalContextType;

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      ApiInstance.get_last_purchase((purchase) => {
        console.log("get_last_purchase ticket =", purchase);
        if (purchase && Object.keys(purchase).length) {
          setLastPurchaseTicketList(purchase);
          setTicketList([purchase]);
          // purchase_stored: this method to tell the server that the purchased tickets have been safely stored in the client's
          // browser-local storage (displayed later on the purchased tickets page)
          ApiInstance.purchase_stored(purchase.purchaseid);
        }
      });
      ApiSessionInstance.clearDownloadTickets();
      ApiSessionInstance.resetShowPaymentGateway();
      ApiSessionInstance.removePurchaseWithoutRegisteringMounted();
    }
  }, [isMounted, setLastPurchaseTicketList, setTicketList]);

  const onHideHandler = () => {
    navigate("/");
  };

  // IF previous and new tickets empty
  if (
    (!ticketList.length || !Object.keys(ticketList[0]).length) &&
    (!lastPurchaseTicketList ||
      !Object.keys(lastPurchaseTicketList || {}).length)
  ) {
    return (
      <Layout>
        <TextsComponent
          show={props.show !== undefined ? props.show : !!matches}
          title="Purchase History"
          isEmpty={!ticketList.length}
          texts={[
            "There are currently no tickets in your purchase history.",
            // "Please buy a ticket or sign in to your account for more information.",
            // "When purchasing a ticket without an account any ticket information will only be stored temporarily on this application.",
            // "Please register or sign in to your account to have your full purchase history available.",
          ]}
        />
      </Layout>
    );
  }

  return (
    <Layout>
      {isLargeScreenBelow ? (
        <Modal
          contentClassName="backgroundDropdown px-2 px-lg-5 py-4 rounded-4"
          backdropClassName="bg-transparent"
          show={props.show !== undefined ? props.show : !!matches}
          onHide={onHideHandler}
          size="xl"
          centered
        >
          <h1
            className="text-capitalize text-primary text-center fs-40 mb-0"
            style={{
              fontWeight: 600,
              marginTop: "60px",
              letterSpacing: "2.7px",
            }}
          >
            PURCHASE HISTORY
          </h1>
          <Button
            onClick={() => navigate("/")}
            variant="white"
            className="text-white position-absolute end-0 mx-3"
          >
            <i className="bi bi-x display-3" />
          </Button>
          ;
          <h1
            className="text-center fs-20 text-white mb-5"
            style={{ fontWeight: 500 }}
          >
            Your ticket has been sent to your email address.
          </h1>
          <Orders />
        </Modal>
      ) : (
        <SmallScreenPurchaseHistory />
      )}
    </Layout>
  );
};

export default PurchaseHistory;
