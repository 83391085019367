import { Table } from "react-bootstrap";
import SimpleBar from "simplebar-react";
import {
  IPurchasedTicket,
  IPurchasedTicketWithCount,
} from "../../../helpers/models/txticket_api.model";
import OrderItem from "./OrderItem/OrderItem";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

interface OrdersProps {
  ticketList: IPurchasedTicket[];
  currentTickets: IPurchasedTicket[] | undefined;
  maxHeight?: string;
  isLastPurchasedTicket?: boolean;
  setPurchasedTickets: (
    ticketList: IPurchasedTicket[],
    isLastPurchase: boolean,
  ) => void;
}

const Order = ({
  ticketList,
  maxHeight,
  currentTickets,
  setPurchasedTickets,
  isLastPurchasedTicket,
 
}: OrdersProps) => {
  const navigate = useNavigate();
  const isLargeScreenBelow = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const [newPurchasedTickets, setNewPurchasedTickets] = useState<
    IPurchasedTicketWithCount[]
  >([]);

  useEffect(() => {
    if (ticketList) {
      // group tickets of the same reservation class, date and location
      const ticketListIds = ticketList.map(
        (sc) => sc.tinception + sc.stid1 + sc.stid2 + sc.pv_reservation_class,
      );

      const ticketListUniqueIdSets = new Set(ticketListIds);
      const ticketListUniqueIds = Array.from(ticketListUniqueIdSets.values());
      const newPurchasedTickets: IPurchasedTicketWithCount[] = [];

      ticketListUniqueIds.map((tluId) => {
        const len = ticketListIds.filter((tli) => tli === tluId).length;

        let price = 0;

        ticketList.filter((sc) => {
          const key =
            sc.tinception + sc.stid1 + sc.stid2 + sc.pv_reservation_class;
          if (key === tluId) price += sc.centprice;
          return sc;
        });

        const ticketIndex = ticketListIds.findIndex(
          (tli: string) => tli === tluId,
        );
        const newCart = {
          ...ticketList[ticketIndex],
          count: len, // count
          centprice: price,
        };

        newPurchasedTickets.push(newCart);
        return tluId;
      });
      setNewPurchasedTickets(newPurchasedTickets);
    }
  }, [ticketList]);

  const navigateOnMobile = () => {
    navigate("/purchased-ticket/show-ticket");
    if (!isLargeScreenBelow) {
       navigate("/purchased-ticket/show-ticket");
    }
  }

  const handleTicketClicked = (eachPurchasedTicket: IPurchasedTicket[]) => {
    if (isLastPurchasedTicket) {
      setPurchasedTickets(ticketList, isLastPurchasedTicket);
      navigateOnMobile()
      return;
    }
    // setPurchasedTickets(eachPurchasedTicket, false);
    setPurchasedTickets(ticketList, false);
    navigateOnMobile()
  };

  return (
    <SimpleBar autoHide={false} forceVisible={'y'} style={{ maxHeight: maxHeight || "400px", }} className="">
      <Table className="align-middle table-nowrap mb-0 bg-transparent">
        <tbody className="text-white">
          {newPurchasedTickets.map((eachPurchasedTicket) => (
            <OrderItem
              key={eachPurchasedTicket.tno}
              purchasedTicket={eachPurchasedTicket}
              handleTicketClicked={handleTicketClicked}
              currentTickets={currentTickets}
              isLastPurchasedTicket={isLastPurchasedTicket}
            />
          ))}
        </tbody>
      </Table>
    </SimpleBar>
  );
};

export default Order;
