import { Button, Modal } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Content from "./Content/Content";
import React from "react";
import SmallScreenPurchasedTicketLocked from "./SmallScreen";
import Layout from "../../Layout";

interface IPurchasedTicketLockedProps {
  children?: React.ReactNode; // For reusability on buy page
}

const PurchasedTicketLocked = (props: IPurchasedTicketLockedProps) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/purchased-ticket/:purchaseId/locked" }];
  const matches = matchRoutes(someRoutes, currentLocation);

  const isEmpty = true;
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });

  if (isLargeScreen) {
    return (
      <Layout>
        <Modal
          contentClassName={`bg-dark py-4 rounded-4 backgroundDropdown`}
          backdropClassName="bg-transparent"
          show={!!matches}
          size="xl"
          centered
        >
          <h4 className="text-capitalize text-primary text-center mb-4" style={{fontSize:"35px", fontWeight:" 550"}}>
            Your Ticket
          </h4>
          <Button
            onClick={() => navigate("/purchase-history")}
            variant="white"
            className="text-white position-absolute end-0 mx-3 top-0"
          >
            <i className="bi bi-x display-3" />
          </Button>
          <Content {...props} isLargeScreen />
        </Modal>
      </Layout>
    );
  }

  // if (isEmpty && !isMediumScreen) {
  if (isEmpty) {
    const contentClass = isEmpty ? "border border-primary" : "";
    return (
      <Layout>
        <Modal
          contentClassName={`bg-dark mr-2 px-lg-5 rounded-4 ${contentClass}`}
          backdropClassName="bg-transparent"
          show={!!matches}
          size="xl"
          centered={isMediumScreen}
        >
          <SmallScreenPurchasedTicketLocked {...props} />
        </Modal>
      </Layout>
    );
  }

  return (
    <Layout>
      <SmallScreenPurchasedTicketLocked {...props} />
    </Layout>
  );
};

export default PurchasedTicketLocked;
