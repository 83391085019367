import React, { ReactNode, useEffect, useState } from "react";

import Navigation from "../component/Navigation/Navigation";
import { ContinuePurchase } from "../component/ContinuePurchase/ContinuePurchase";
import Footer from "../component/Footer";
import { Col, Container, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import Heading from "../component/Heading/Heading";
import { useMediaQuery } from "react-responsive";

interface LayoutProps {
  children: ReactNode;
}
const Layout = (props: LayoutProps) => {
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 868px)",
  })

  const isSmallScreenBelow = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const [isScrollable, setIsScrollable] = useState(false);
  // Function to check if the page is scrollable
  const checkScrollable = () => {
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY
    // if (scrolled === 0) {
    //   setIsScrollable(true);
    // } 
    if (scrollableHeight > 0 && scrolled < scrollableHeight) {
      setIsScrollable(true);
    } 
    if (window.scrollY >30 ){
      setIsScrollable(false);
    }
    // const scrollable = document.documentElement.scrollHeight > window.innerHeight;
    // setIsScrollable(scrollable);
    
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Check if the page is scrollable when the component mounts
    checkScrollable();

    // Check for changes in screen size (resize event)
    window.addEventListener('resize', checkScrollable);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);

  const toggleBodyClass = () => {
    const header = document.querySelector("#page-topbar");
    if (window.scrollY < 20) {
      if (header) header.classList.remove("bg-dark");
    } else {
      if (header) header.classList.add("bg-dark");
    }
  };

  useEffect(() => {
    if (isSmallScreenBelow) {
      window.addEventListener("scroll", toggleBodyClass);
      toggleBodyClass();
      return () => window.removeEventListener("scroll", toggleBodyClass);
    }
  }, [isSmallScreenBelow]);

  const rowClass = isMediumScreen ? "home-container" : "";
  // const contentClass = isMediumScreen ? "content h-auto" : "mt-3";
  const contentClass = isMediumScreen ? "" : "mt-3";
  const contentClassDesktop = isLargeScreen ? "content" : "mt-3";
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Navigation />
        <div className="main-content">
          <div className="page-content d-print-none" style={{ height:"100%"}}>
            <Container className="my-auto overflow-hidden page-inner-content">
              <Row className={`${rowClass} align-items-center d-flex`}>
                <Heading />
                <Col md={12} lg={6}>
                  <Col md={12} lg={9} xl={10} xxl={10} className="m-auto">
                    <div className={`${contentClass} ${contentClassDesktop} child-container w-100`}>
                      {props.children}
                    </div>
                  </Col>
                </Col>
              </Row>
            </Container>
            <Footer />
            <ContinuePurchase />
            <ToastContainer />
            
          </div>
        </div>
       {isSmallScreenBelow?    <div>

         {isScrollable &&  (
        <button
          onClick={scrollToBottom}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: '#00f600',
            width: '35px',
            height:"40px",
            color: 'black',
            border: 'green',
            borderRadius: '50%',
            // padding: '5px 20px',
            paddingTop:'-5px',
            cursor: 'pointer',
            zIndex: 1000,
            fontSize: '25px',
          }}
        >
          ↓
        </button>
      )}
      </div>: null}
        </div>
    </React.Fragment>
  );
};

export default Layout;
