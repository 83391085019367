import React from "react";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../../context/GlobalContext";
import { IPurchasedTicket } from "../../../../helpers/models/txticket_api.model";
import moment from "moment";
import validLogo from "../../../../assets/images/valid2.svg";
import { useMediaQuery } from "react-responsive";

interface TicketItemProps {
  purchasedTicket: IPurchasedTicket;
}

export default function VerifiedTicket() {
  const { ValidTicket } = React.useContext(GlobalContext) as GlobalContextType;
  const isMobile = useMediaQuery({
    query: "(min-width: 768px)",
  })
  const day = ValidTicket?.inception;
  return (
    <div className="d-flex" style={{ flexDirection:"column",}}>
      <header
        className="text-primary text-align-center fs-25 fw-bolder"
        style={{ marginTop: "-40px", marginLeft: isMobile? "" :"15%" }}
      >
        YOUR TICKET STATUS
      </header>
      <div
        className="d-flex "
        style={{
          width:isMobile? "100%" : "70%",
          height: "20%",
          justifyContent: "center",
          alignItems: "center",
          margin: "30px 0px",
          gap: "10px",
           marginLeft: isMobile? "" :"18%"
        }}
      >
        <img src={validLogo} alt="valid-img" style={{ height:isMobile? "100%" : "90%", width:isMobile ? "67%" : "100%" }} />
        
      </div>
      {/* &gt; */}
      <div
        className="d-flex"
        style={{
          textAlign: "left",
          width: "90%",
          height: "40%",
          margin: "auto",
          gap: "20px",
          color:"white",
          marginLeft: isMobile? "5%" :"2%",
          marginTop: isMobile?" -5px" :"",
          marginBottom: isMobile?" 10px" :""
        }}
      >
        <div
          className="d-flex"
          style={{ flexDirection: "column", justifyContent: "left" }}
        >
          <span>NO:</span>
          <span>TYPE:</span>
          <span>TRIP:</span>
          <span>DEPT:</span>
          <span>VALID:</span>
          <span>TRAIN:</span>
          <span>PRICE:</span>
          <span>PAID:</span>
        </div>
        <div
          className="d-flex"
          style={{ flexDirection: "column", justifyContent: "left" }}
        >
          <span>{ValidTicket?.tno}</span>

          <span>{ValidTicket?.pr_name}</span>
          <span>
            {ValidTicket?.stid1_name} - {ValidTicket?.stid2_name}
          </span>
          {ValidTicket?.tno ? (
            <span>
              {" "}
              {moment(ValidTicket.rvlist[0].tod2, "HH:mm:ss").format(
                "hh:mm A",
              )}{" "}
            </span>
          ) : null}
          {ValidTicket?.inception ? (
            <div>
              <span>
                {moment(new Date(ValidTicket?.inception)).format("dddd")},{" "}
                {moment(new Date(ValidTicket?.inception)).format("D")}
                <sup>
                  {moment(new Date(ValidTicket?.inception))
                    .format("Do")
                    .replace(/\d+/g, "")}
                </sup>{" "}
                {moment(new Date(ValidTicket?.inception)).format("MMMM")}{" "}
                {moment(new Date(ValidTicket?.inception)).format("YYYY")}
                {/* {moment(
                    shoppingCartBasket.route.leg[0].tod_arr,
                    "HH:mm:ss",
                  ).format("hh:mm A")},{" "} */}
              </span>
            </div>
          ) : null}
          <span>free setting</span>
          {ValidTicket?.centprice ? (
            <span>
              {" "}
              <strong className="">
                ₦
                {(ValidTicket?.centprice / 100 || 0).toLocaleString(
                  navigator.language,
                  {
                    minimumFractionDigits: 0,
                  },
                )}
                .00
              </strong>
            </span>
          ) : null}
          <span> {ValidTicket?.time_created ?( <span>
                {moment(new Date(ValidTicket?.time_created)).format("dddd")},{" "}
                {moment(new Date(ValidTicket?.time_created)).format("D")}
                <sup>
                  {moment(new Date(ValidTicket?.time_created))
                    .format("Do")
                    .replace(/\d+/g, "")}
                </sup>{" "}
                {moment(new Date(ValidTicket?.time_created)).format("MMMM")}{" "}
                {moment(new Date(ValidTicket?.time_created)).format("YYYY")},
                {" "}
              {moment(ValidTicket?.time_created, "HH:mm:ss").format(
                "hh:mm A",
              )}{" "}
                
              </span>) : null}</span>

        </div>
      </div>
      <div className="text-primary fs-13 fw-bold" style={{marginLeft: isMobile? "" :"15%", marginTop: isMobile? "" : "10px", marginBottom: isMobile? "" : "10px"}}>
        THIS IS FOR YOUR INFORMATION ONLY
      </div>
      <div>
        <p
          className="fs-12 mb-2 text-white"
          style={{ textAlign: "left", width:isMobile? "90%":"100%", margin: "auto" }}
        >
          This is your ticket data saved in the QR-code. For boarding and
          validating, do utilise the QR-code on your online, hardcopy or pdf
          ticket
        </p>
        <p
          className="fs-12 text-white"
          style={{ textAlign: "left", width:isMobile? "90%" :"100%", margin: "auto", marginBottom: isMobile? "" :"30%"}}
        >
          Your tickets are available for viewing in the "purchase History" of
          the app or as PDF that is attached to the email issued to you upon
          purchase.
        </p>
      </div>
    </div>
  );
}
