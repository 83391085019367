import { Button, Col } from "react-bootstrap";
import GoButton from "../../../component/GoButton/GoButton";
import { IRouteConnection } from "../../../helpers/models/route.model";
import SelectTicketDropdown from "../Forms/SelectTicketDropdown";
import {
  ITicketOrder,
  ITicketPrice,
} from "../../../helpers/models/txticket_api.model";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiSessionInstance from "../../../helpers/session_api";
import { useMediaQuery } from "react-responsive";
import {
  BookingContext,
  BookingContextType,
} from "../../../context/BookingContext";
import { GlobalContext, GlobalContextType } from "../../../context/GlobalContext";

interface ITicketOptionsProps {
  tickets: IRouteConnection[];
  ticketPrices:
    | undefined
    | {
        [key: string]: ITicketPrice[];
      };
  ticketPrice?: ITicketPrice;
  selectedDate: string;
  passengers: number;
  hasConfirmedBooking: boolean;
  isBooking: boolean;
  handleTicketPriceSelection: (selected: ITicketPrice) => void;
  handleTicketSelection: (selected: IRouteConnection) => void;
  ticket?: IRouteConnection;
  selectedStations: { from: string; to: string };
  handleSetPassenger: (num: number) => void;
  handleTicketsOrder: React.Dispatch<React.SetStateAction<ITicketOrder[]>>;
  handleBookingConfirmation: (isConfirmed: boolean) => void;
  resetTripSelectionBooking: () => void;
  handleBookingSubmission: () => void;
  isShoppingCartReady: boolean;
}

function TicketOptions({
  selectedStations,
  tickets,
  ticket,
  ticketPrice,
  ticketPrices,
  selectedDate,
  isBooking,
  handleTicketSelection,
  handleTicketPriceSelection,
  passengers,
  handleSetPassenger,
  handleTicketsOrder,
  hasConfirmedBooking,
  handleBookingConfirmation,
  resetTripSelectionBooking,
  handleBookingSubmission,
  isShoppingCartReady,
}: ITicketOptionsProps) {
  const [state, setState] = useState(passengers);
  const { getBookingInfo } = React.useContext(
    BookingContext,
  ) as BookingContextType;

  const { shoppingCartBasket } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const smallDevice = useMediaQuery({
    query: "(max-width:330px)",
  });
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const timeClass = !state ? "text-primary" : "text-white";
  useEffect(() => {
    const bookingSection = document.getElementById("start-here");
    if (bookingSection) {
      window.scrollTo({ top: bookingSection.offsetTop, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (!ApiSessionInstance.getConfirmBooking() && hasConfirmedBooking) {
      ApiSessionInstance.onConfirmBooking();
    }
  }, [hasConfirmedBooking]);

  const onAddPassengers = () => {
    if (state > 0 && state < 3 && shoppingCartBasket.length  === 0) {
      console.log("ticketPrice", ticketPrice);
      handleTicketsOrder((prev) => {
        const newState = [
          ...prev,
          {
            prid: ticketPrice?.prid as number,
            pvid: ticketPrice?.pvid as number,
            route: ticket as IRouteConnection,
            centprice: ticketPrice?.centprice as number,
            id: ticketPrice?.prid as number,
          },
        ];
        setState((prevState) =>prevState + 1);
        handleSetPassenger( state + 1);
        return newState;
      });
      

      
    }else if(shoppingCartBasket.length > 0 && shoppingCartBasket.length <2 && state < 2){
      handleTicketsOrder((prev) => {
        const newState = [
          ...prev,
          {
            prid: ticketPrice?.prid as number,
            pvid: ticketPrice?.pvid as number,
            route: ticket as IRouteConnection,
            centprice: ticketPrice?.centprice as number,
            id: ticketPrice?.prid as number,
          },
        ];
        setState(state + 1);
        handleSetPassenger(state + 1);
        return newState;
      });
    }else{
      toast.warn("You have two tickets in the shopping cart and can purchase one more due to NRC's policy. Please click 'GO' to continue or 'Shopping Cart' to proceed with payment or remove tickets.", {
        autoClose: 15000,
      })
    }
  };

  const onDeletePassengers = () => {
    if (state > 1) {
      handleTicketsOrder((prev) => {
        const initialState = [...prev];
        initialState.pop();
        return initialState;
      });
      setState(state - 1);
      handleSetPassenger(state - 1);
    } else {
      // toast.warning(
      //   "there is no ticket left to delete from the shopping_basket!!",
      // );
    }
  };
  return (
    <Fragment>
      <Col
        md={9}
        className={`${smallDevice ? "fs-12" : "fs-14"} ${
          smallDevice ? "mt-1" : "mt-3"
        } mx-auto`}
        id="start-here"
      >
        <p
          className="text-white-50 text-center d-flex justify-content-between mx-auto"
          style={{
            maxWidth: "310px",
            marginBottom: "5px",
            marginTop: smallDevice ? "-13px" : "",
            fontWeight: "600",
          }}
        >
          <span className="text-white">Booking</span>
          <span className="text-white">&gt;</span>
          <span>Shopping Cart</span>
          <span>&gt;</span>
          <span> Buy </span>
          <span>&gt;</span>
          <span> Ticket </span>
        </p>
      </Col>
      <Col
        style={{ background: "linear-gradient(120deg, #000000 40%,  #5E5E5E)" }}
        md={9}
        className="m-auto border border-primary rounded-4 p-2 text-center"
      >
        <h6
          className={`${
            smallDevice ? "fs-16" : "fs-19"
          } text-white text-uppercase m-0`}
          style={{ letterSpacing: "2.1px", fontWeight: 700 }}
        >
          Trip
        </h6>
        <p
          className={`${smallDevice ? "fs-12" : "fs-15"} mb-2 text-white`}
          style={{
            letterSpacing: "1.1px",
            fontWeight: 500,
            marginTop: "-5px",
            marginLeft: "-2px",
          }}
        >
          {selectedStations.from} -&gt; {selectedStations.to}
        </p>
        <h6
          className={`${
            smallDevice ? "fs-16" : "fs-19"
          } text-white text-uppercase`}
          style={{
            letterSpacing: "2.1px",
            fontWeight: 700,
            marginTop: smallDevice ? "-5px" : "15px",
          }}
        >
          Date
        </h6>
        <p
          className={`${smallDevice ? "fs-12" : "fs-15"} mb-2 text-white`}
          style={{
            letterSpacing: "1.8px",
            fontWeight: 400,
            marginTop: "-10px",
          }}
        >
          {moment(new Date(selectedDate)).format("dddd")},{" "}
          {moment(new Date(selectedDate)).format("D")}
          <sup>
            {moment(new Date(selectedDate)).format("Do").replace(/\d+/g, "")}
          </sup>{" "}
          {moment(new Date(selectedDate)).format("MMMM")}{" "}
          {moment(new Date(selectedDate)).format("YYYY")}
        </p>
        {/* <h6
          className="fs-18 text-white text-uppercase m-0"
          style={{ letterSpacing: "2.1px", fontWeight: 700 }}
        >
          Time and Class
        </h6> */}
        {hasConfirmedBooking? null :
        <SelectTicketDropdown
          text="Please choose time and class?"
          tickets={tickets}
          ticket={ticket}
          ticketPrice={ticketPrice}
          ticketPrices={ticketPrices}
          passengers={passengers}
          hasConfirmedBooking={hasConfirmedBooking}
          handleTicketSelection={handleTicketSelection}
          handleTicketPriceSelection={handleTicketPriceSelection}
          handleTicketsOrder={handleTicketsOrder}
        />
        }

        {hasConfirmedBooking ? (
          <Fragment>
            <h6
              className={`${timeClass} ${
                smallDevice ? "fs-14" : "fs-19"
              } text-uppercase`}
              style={{
                letterSpacing: "2.1px",
                fontWeight: 700,
                marginTop: smallDevice ? "-10px !important" : "",
                marginBottom: 0,
              }}
            >
              Time and Class
            </h6>

            <div
              style={{
                fontWeight: state ? 500 : 600,
                // padding: 0,
                letterSpacing: "1.2px",
                padding: "10px",
                margin: "0",
                // whiteSpace: "pre-wrap",
                marginTop: state ? "-8px" : "0",
                color: state ? "white " : "black",
                marginBottom: isMediumScreen ? "-5px" : "",
              }}
            >
              {getBookingInfo}
            </div>
            <h6
              className="fs-19 text-white text-uppercase m-0"
              style={{ letterSpacing: "2.1px", fontWeight: 700 }}
            >
              Type
            </h6>
            <p
              className="fs-14 mb-2 text-white"
              style={{
                color: "",
                marginTop: "-5px",
                fontWeight: 500,
                letterSpacing: "2.2px",
              }}
            >
              Free Seating Policy
            </p>

            <p className="fs-12 mb-0 text-white">
              Please click „<span className="text-primary">GO</span>“ to proceed
              to the„Shopping Cart“ where you can delete and add tickets.
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <h6
              className={`${smallDevice ? "fs-17" : "fs-19"} m-0`}
              style={{
                letterSpacing: "2.1px",
                fontWeight: 700,
                opacity: !ticketPrice || !ticket ? 0.9 : 1,
                color: ticket ? "#00f600" : "#34741B",
                marginTop: smallDevice ? "-15px" : "",
              }}
            >
              Passengers
            </h6>
            <div className="btn-group w-100 mt-0  mb-1">
              <Button
                disabled={!ticketPrice || !ticket ? true : false}
                onClick={onDeletePassengers}
                style={{ fontWeight: 900, color: "black" }}
                className={`btn-passenger ${smallDevice ? "fs-16" : "fs-20"} `}
              >
                {/* <i className="bi bi-dash display-8"></i> */}-
              </Button>
              <Button
                className={`btn-passenger ${smallDevice ? "fs-14" : "fs-20"} `}
                style={{ color: "black", fontWeight: 800 }}
                disabled={!ticketPrice || !ticket}
              >
                {state}
              </Button>
              <Button
                disabled={!ticketPrice || !ticket}
                onClick={onAddPassengers}
                style={{ fontWeight: 900, color: "black" }}
                className={`btn-passenger ${smallDevice ? "fs-16" : "fs-20"} `}
              >
                {/* <i className="bi bi-plus display-8"></i> */}+
              </Button>
            </div>
          </Fragment>
        )}
      </Col>
      <GoButton
        disabled={!ticketPrice || !ticket}
        onClick={() =>
          !hasConfirmedBooking
            ? handleBookingConfirmation(true)
            : handleBookingSubmission()
        }
        disabledNextButton={
          !hasConfirmedBooking
            ? !ApiSessionInstance.getConfirmBooking()
            : !isShoppingCartReady
        }
        isLoading={isShoppingCartReady}
        onClickNextButton={() =>
          !hasConfirmedBooking
            ? handleBookingConfirmation(true)
            : handleBookingSubmission()
        }
        onClickBackButton={() =>
          isBooking && hasConfirmedBooking
            ? handleBookingConfirmation(false)
            : resetTripSelectionBooking()
        }
        disabledBackButton={!isBooking}
        showGroupButtons
      />
      <Col
        md={9}
        className="d-block d-md-none"
        style={{ marginTop: "-10px", fontWeight: 600 }}
      >
        <p className="text-white fs-14 mt-2">
          Total Price: Naira{" "}
          {ticketPrice ? (ticketPrice.centprice / 100) * passengers : 0}
        </p>
      </Col>
    </Fragment>
  );
}

export default TicketOptions;
