import { Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import qrpics from "../../../assets/images/ticket/qrpics.png";
import locked from "../../../assets/images/lock_close.svg"
import { GlobalContext, GlobalContextType } from "../../../context/GlobalContext";
import { useContext } from "react";
import QRCode from "react-qr-code";

interface ContentProps {
  isEmpty?: boolean;
  isLargeScreen?: boolean;
  children?: React.ReactNode; // For reusability on buy page
}

const Content = ({ isEmpty, isLargeScreen }: ContentProps) => {
  const navigate = useNavigate();
  const { viewQrcode } = useContext(GlobalContext) as GlobalContextType;

  const newData = viewQrcode !== undefined ? viewQrcode : ""

  return (
    <Fragment>
      {/* <div className="text-light-emphasis d-flex flex-column justify-content-center position-relative"> */}
      <div className="position-relative w-100 d-flex d-sm-none text-light-emphasis justify-content-center"  dangerouslySetInnerHTML={{
                    __html: newData
                  }}>
        {/* <img
          onClick={() => navigate("/purchased-ticket/1")}
          onDoubleClick={() => navigate("/purchased-ticket/1")}
          style={{ maxWidth: "85%" }}
          className="mx-auto w-100 border border-3 border-danger"
          src={qrpics}
          alt="qrpics"
        /> */}
      </div>
      <Row className="d-none d-sm-flex text-light-emphasis">
        <Col sm={1} className="position-relative">
          <p className="opacity-0">screen</p>
        </Col>
        <Col style={{width:"30%", display:"flex", justifyContent:"center", alignItems:"center",}}>
        <div style={{width:"50%",border:"4px solid red", borderRadius:"5%", overflow:"hidden"}}  dangerouslySetInnerHTML={{
                    __html: newData
                  }}>

        </div>
          {/* <img
            className="mx-auto w-100 border border-3 border-danger"
            src={qrpics}
            alt="qrpics"
            onClick={() => navigate("/purchased-ticket/1")}
            onDoubleClick={() => navigate("/purchased-ticket/1")}
          /> */}
        </Col>
        <Col sm={1} className="position-relative">
          <p className="opacity-0">screen</p>
        </Col>
      </Row>
      <p className="text-danger fs-20 text-center my-2" style={{width:"100%", height:"30%"}}>
        <img src={locked} alt="lock" style={{width:"40%", cursor:"pointer"}} onClick={() => navigate("/purchased-ticket/1")}
            onDoubleClick={() => navigate("/purchased-ticket/1")} />
      </p>
    </Fragment>
  );
};

export default Content;
