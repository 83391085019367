import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Fragment } from "react/jsx-runtime";
import GoButton from "../../../component/GoButton/GoButton";
import {
  GlobalContext,
  GlobalContextType,
} from "../../../context/GlobalContext";
import { useContext, useEffect, useRef, useState } from "react";
import ApiSessionInstance from "../../../helpers/session_api";
import { useMediaQuery } from "react-responsive";
import ApiInstance from "../../../helpers/txticket_api";
import { toast } from "react-toastify";
import {
  // IShoppingBasketData,
  IStartPaymentResponse,
} from "../../../helpers/models/txticket_api.model";
import { IIcadPayPaymentResponse } from "../../../helpers/models/payment_gateway.model";
// import OrderHeading from "../../ShoppingCart/OrderHeading/OrderHeading";
// import OrderItem from "../../ShoppingCart/Orders/OrderItem/OrderItem";
import moment from "moment";
import { FormikProvider } from "formik";
import { FieldArray } from "formik";
import { IBuyerDetailsDto } from "../../../helpers/models/auth.model";
import SimpleBar from "simplebar-react";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";

interface PurchaseWithoutRegisteringFormProps {
  children?: React.ReactNode; // For reusability on buy page
}
// interface IBuyProps {
//   children?: React.ReactNode; // For reusability on buy page
// }
interface FocusableInput {
  value: string;
  ref: React.RefObject<HTMLInputElement>;
}

type HandleKeyDown = (
  e: React.KeyboardEvent<HTMLInputElement>,
  currentInput: FocusableInput,
  nextInput: FocusableInput
) => void;

declare global {
  interface Window {
    IcadPay: any;
  }
}


const handleKeyDown: HandleKeyDown = (e, currentInput, nextInput) => {
  // Move to next input if current input has data and user presses Enter or Tab
  if (
    (e.key === 'Enter' || e.key === 'Tab') && 
    currentInput.value && 
    currentInput.value.trim() !== '' &&
    nextInput.ref.current
  ) {
    e.preventDefault();
    nextInput.ref.current.focus();
  }
};

// Auto-focus next input when current input gets data
const handleInputChange = (
  currentValue: string,
  nextInputRef: React.RefObject<HTMLInputElement>
) => {
  if (currentValue && currentValue.trim() !== '' && nextInputRef.current) {
    nextInputRef.current.focus();
  }
};

const PurchaseWithoutRegisteringForm = ({
  children,
}: PurchaseWithoutRegisteringFormProps) => {
  const navigate = useNavigate();
  const { updateBuyerDetails, buyerDetails, user, shoppingCartBasket } =
    useContext(GlobalContext) as GlobalContextType;

  const [isValid, setIsValid] = useState<boolean>(false);
  const [state, setState] = useState<any[]>([]);
  const [narration] = useState("NRC - ticket purchase");

  const initialShoppingCart = state && Array.isArray(state) ? state : [{ email: '', confirmEmail: '', firstName: '', lastName: '' }];
  const formik: any = useFormik({
    enableReinitialize: true,
    isInitialValid: !!buyerDetails,
    initialValues:  { shoppingCart: initialShoppingCart },
    validationSchema: Yup.object({
      shoppingCart: Yup.array().of(
        Yup.object({
          // nin: Yup.number().required("Please Enter NIN"),
          firstName: Yup.string().required("Please Enter First Name"),
          lastName: Yup.string().required("Please Enter Last Name"),
          email: Yup.string().email().required("Please Enter address"),
          confirmEmail: Yup.string()
            .email()
            .required("Please confirm your email")
            .email()
            .oneOf([Yup.ref("email"), null], "Email must match"),
        }),
      ),
    }),

    onSubmit: (values: any) => {
      const userData = {
        // nin: values["nin"],
        firstName: values.shoppingCart[0]["firstName"],
        lastName: values.shoppingCart[0]["lastName"],
        email: values.shoppingCart[0]["email"],
        confirmEmail: values.shoppingCart[0]["confirmEmail"],
      };
      updateBuyerDetails(userData);
      values.shoppingCart.map((sc: any) => {
        ApiInstance.set_pax_given_name(sc.biunique, sc.firstName);
        ApiInstance.set_pax_surname(sc.biunique, sc.lastName);
      });
      setState([]);
      ApiSessionInstance.onConfirmBuyerDetails();
      onStartPurchase(userData);
      // navigate("/buy");
    },
  });

  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isLargeScreen = useMediaQuery({
    query: "(min-width: 968px)",
  });

  const smallDevice = !isMediumScreen ? "" : "mx-auto";

  const getPaymentRefProviderName = () => {
    if (ApiSessionInstance.getStartPayment()) {
      return ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
    }
    return null;
  };

  const onPaymentSuccess = (response: IIcadPayPaymentResponse) => {
    console.log("onPaymentSuccess response", response);
    navigate("/buying-processed-loading");
  };

  const onPaymentError = (response: any) => {
    ApiSessionInstance.clearAbruptlyCancelledPurchase();
    ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    console.log("onPaymentError response", response);
    toast.error("ticket purchase failed..");
    // if (state) {
    if (ApiSessionInstance.getStartPayment()) {
      // ApiInstance.cancel_payment(selectedPaymentProvider, state["pmtref"]);
      const dt = ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
      ApiInstance.cancel_payment(dt.pgwname, dt["pmtref"]);
      ApiSessionInstance.resetStartPayment();
    }
  };

  const onClosePayment = () => {
    // const pmtref = ApiInstance.get_last_pmtref();
    // console.log('onClosePayment pmtref', pmtref)
    toast.success("payment window closed");
    // if (state) {
    if (ApiSessionInstance.getStartPayment()) {
      // ApiInstance.cancel_payment(selectedPaymentProvider, state["pmtref"]);
      const dt = ApiSessionInstance.getStartPayment() as IStartPaymentResponse;
      ApiInstance.cancel_payment(dt.pgwname, dt["pmtref"]);
      ApiSessionInstance.clearAbruptlyCancelledPurchase();
      ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
      ApiSessionInstance.resetStartPayment();
    }
    navigate("/shopping-cart");
    // DynamicStylerInstance.applyStyles(modalStyle)
    window.location.reload();
  };

  const onStartPurchase = (userData: IBuyerDetailsDto) => {
    const currentUser = buyerDetails || userData;
    if (!currentUser) return;
    const totalShoppingCartBasketPrice =
      ApiInstance.get_shopping_basket_amount();
    const email = user ? user.email : currentUser.email;
    const firstName = user ? user.firstName : currentUser.firstName;
    const lastName = user ? user.lastName : currentUser.lastName;
    const telephone = user ? user.telephone : currentUser.telephone;
    ApiSessionInstance.clearAbruptlyCancelledPurchase();
    ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
    ApiInstance.start_payment(
      "icadpay",
      email,
      `${narration} by ${firstName} ${lastName}`,
      (data) => {
        ApiSessionInstance.clearAbruptlyCancelledPurchase();
        ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
        navigate("/buying", { state: { method: "buying", data } });
        ApiSessionInstance.onStartPayment(data);
        const payload = {
          // key: "live_NDNlMGM5ZjJlYzliZTBmYjNiMjgzOGFlNzkyZGQ4MTBjZDFmMjQ2YjYzZWRhMmNkYjJhOGMwYWNjZTA3MDJmNA", // this is not a demo key.
          // key: "test_MDE0OTY1Y2NjNDI0MjIyZjY1ZWYwOWQ1YzkyMmJjZTZkYzlhZDBiZDVkOTg5ZDBmZjllYTMyMzVjNTI4MmJmMQ", // this is a demo key.
          // key: 'test_YzA1MmNmYzE0OTc1Y2QyM2ViNWUwNTIxOGMzZjA2MjI5N2M4ZDU3YmY5ZDg1ZmU1OWIwNWE1NDU0YjkzYTZkOQ', // this is a demo key.
          key: "test_NjA0N2QzZjg1NjNjOGIwYTNmYzIwZjNmZTY1MGE0NDZmYjk1OTgxNWMzODdiOTFiYzVlNzM5YWM4MGU5NDM0Nw",
          email: currentUser.email, // customer email
          amount: totalShoppingCartBasketPrice / 100, // amount to be processed
          currency: "NGN", // currency
          first_name: firstName,
          last_name: lastName,
          phone_number: telephone, // customer's phone number (optional)
          customerId: email,
          ref: data["pmtref"],
          // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
          narration: `${narration} by ${firstName} ${lastName}`,
          isBill: true,
          service_bill: "",
          callback_url: "https://nrc-icad.txware.com/", // specified redirect URL (potional)
          // callback_url: '', // specified redirect URL (optional)
          callback: (response: any) => {
            if (
              response.requestSuccessful === true &&
              response.responseCode === "00" &&
              response.responseData &&
              response.responseData.responseCode === "00"
            ) {
              const dt = getPaymentRefProviderName();
              ApiSessionInstance.resetStartPayment();
              ApiSessionInstance.clearAbruptlyCancelledPurchase();
              ApiSessionInstance.clearCancelledPurchaseContinuationPrompt();
              ApiSessionInstance.onPaymentSuccessConfirmation({
                response: response.responseData,
                pgwname: "icadpay",
                pmtref: response.responseData.merchantTransactionRef,
                pmtauth: response.responseData.transactionRef,
              });
              // console.log('onPaymentSuccessConfirmation', response);
            }
            // console.log(response);
          },
          onSuccess: onPaymentSuccess,
          onError: onPaymentError,
          onClose: onClosePayment,
        };
        async function iCadPay() {
          if (window.IcadPay) {
            const handler = await window.IcadPay.setup(payload);
            ApiSessionInstance.onShowPaymentGateway();
            console.log(handler);
          }
        }
        iCadPay();
      },
    );
  };

  const validate = (index: number, name: string) => {
    return Object.keys(formik.touched).length &&
      formik.touched.shoppingCart &&
      formik.touched.shoppingCart[index] &&
      formik.touched.shoppingCart[index][name] &&
      Object.keys(formik.errors).length &&
      formik.errors.shoppingCart &&
      formik.errors.shoppingCart[index] &&
      formik.errors.shoppingCart[index][name]
      ? true
      : false;
  };

  useEffect(() => {
    const err = Object.values(formik.errors).flat();
    setIsValid(err.every((v) => v === undefined));
  }, [formik]);

  useEffect(() => {
    if (shoppingCartBasket) {
      const shoppingBag = [...shoppingCartBasket];
      const shc = shoppingBag.map((sc, indx) => ({
        ...sc,
        // nin: "",
        firstName: "",
        lastName: "",
        email: indx !== 0 ? "email@email.com" : "",
        confirmEmail: indx !== 0 ? "email@email.com" : "",
        ...(indx === 0 ? buyerDetails : {}),
      }));
      setState(shc);
    }
  }, [buyerDetails, shoppingCartBasket]);

  const email = formik.values.shoppingCart[0] || {};
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const confirmEmailRef = useRef<HTMLInputElement>(null);
  // Your existing formik setup...

//   const handleKeyDown = <T extends HTMLElement>(e: React.KeyboardEvent<T>, ref: React.RefObject<T>) => {
//     // Your logic here
//     if (e.key === "Enter" || e.key === "Tab") {
//       e.preventDefault(); // Prevent default behavior
//       if (ref.current) {
//         ref.current.focus(); // Move focus to the next input
//       }
//     }
// };

  // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, nextRef: React.RefObject<HTMLInputElement>) => {
  //   if (event.key === "Enter" || event.key === "Tab") {
  //     event.preventDefault(); // Prevent default behavior
  //     if (nextRef.current) {
  //       nextRef.current.focus(); // Move focus to the next input
  //     }
  //   }
  // };
  const arePrerequisitesFilled = (index: number, values: any, email: any) => {
    if (index === 0) {
      return email.email !=="" && email.confirmEmail !== "" && email.email === email.confirmEmail;
    }
    
    // For subsequent indices, check if previous index's lastName is filled
    return index > 0 && 
      formik.values.shoppingCart[index - 1]?.lastName !== "" && 
      email.email !== "" && 
      email.confirmEmail !== "";
  };

  
  
  return (
    <Fragment>
      <Col
        md={9}
        className="fs-14 mt-2 mx-auto mb-0"
        style={{
          marginBottom: !isMediumScreen ? "-10px" : "6px",
          marginTop: !isMediumScreen ? "-40px" : "",
        }}
      >
        <p
          className="text-white-50 text-center d-flex justify-content-between mx-auto"
          style={{
            maxWidth: "300px",
            padding: "0",
            marginBottom: "1px",
            marginTop: !isMediumScreen ? "-10px" : "",
          }}
        >
          <span className="text-white">Booking</span>
          <span className="text-white">&gt;</span>
          <span className="text-white">Shopping Cart</span>
          <span className="text-white">&gt;</span>
          <span className="text-white"> Buy </span>
          <span className="text-white">&gt;</span>
          <span> Ticket </span>
        </p>
      </Col>
       

      <Col
        sm={9}
        md={12}
        lg={12}
        xl={12}
        className={`${smallDevice} border border-primary rounded-4 p-2 px-md-3 py-md-3`}
        
      >

        <div className="d-md-none d-block" style={{ marginBottom: "-10px" }}>
          {/* <h1 className="text-capitalize text-primary text-center pb-0">BUY</h1> */}
        </div>
        <div
          className=" d-block"
          style={{
            marginBottom: "20px",
            paddingTop: isMediumScreen ? "" : "10px",
          }}
        >
          <h1
            className={`text-capitalize text-white text-center p-3 ${ isMediumScreen?"fs-20": "fs-14"}`}
            style={{ fontWeight: "600" }}
          >
            Please Fill In All Details Below.
          </h1>
        </div>

        <Row className="d-flex" style={{width:"90%", margin: "auto", justifyContent:"space-evenly", marginBottom:"20px"}}>
            <Col lg={5} className="mb-2 text-center pt-0">
              <Form.Control
                type="text"
                id={`shoppingCart.${0}.email`}
                // name="email"
                name={`shoppingCart.${0}.email`}
                placeholder="Please type in your email"
                value={email.email}
                // onChange={ (e) => {formik.handleChange(e)
                //   handleInputChange(e.target.value, confirmEmailRef);
                // }}
                 onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // ref={emailRef}
                // onKeyDown={(e) => handleKeyDown(e, confirmEmailRef)}
                // className={getInputStyle('email', formik.values.shoppingCart[0].email)}
                className={`"w-100 custom-input-placeholder ${
                  email.email !== "" ? "activeBorder" : "border border-primary"
                }`}
                isInvalid={!!formik.errors.email}
                style={{
                  color: email.email !== "" ? "white" : "#00f600",
                }}
              />
              {validate(0, "email") ? (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {email.email}
                </Form.Control.Feedback>
              ) : null}
            </Col>
            <Col lg={5} className="mb-2 text-center pt-0">
              <Form.Control
                type="text"
                id={`shoppingCart.${0}.confirmEmail`}
                // name="confirmEmail"
                name={`shoppingCart.${0}.confirmEmail`}
                placeholder="Retype your email to confirm"
                value={email.confirmEmail}
                // onChange={ (e) => {formik.handleChange(e)
                //   handleInputChange(e.target.value, firstNameRef);
                // }}
                 onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // ref={confirmEmailRef}
                // onKeyDown={(e) => handleKeyDown(e, firstNameRef)}
                className={`"w-100 custom-input-placeholder  form-input ${
                  email.confirmEmail !== ""
                    ? "activeBorder"
                    : "border border-primary"
                }`}
                isInvalid={!!formik.errors.confirmEmail}
                style={{
                  color:
                  email.confirmEmail !== ""
                      ?"white" : "#00f600",
                }}
              />
              {validate(0, "confirmEmail") ? (
                <Form.Control.Feedback type="invalid" className="d-block">
                  {formik.errors.shoppingCart[0].confirmEmail}
                </Form.Control.Feedback>
              ) : null}
            </Col>
        </Row>
        <SimpleBar autoHide={false}
         forceVisible={"y"}
         style={{ maxHeight: isMediumScreen? "240px" : "",margin:"10px",}}
         className="col-xl-12 col-lg-12 col-md-9"
         >

        <Col sm={9} md={10} lg={10} className="mx-auto mt-0 mb-1">
          <Form autoComplete="off" style={{marginLeft:"10px"}}>
            <div
              className="d-flex "
              style={{
                paddingBottom: isMediumScreen ? "" : "-15px",
                gap: "30px",
                marginBottom: "10px",
                marginLeft: isMediumScreen ? 20 : "",
              }}
            >
              <div
                className={`${
                  isMediumScreen ? "" : "fs-12"
                } bg-transparent border-0`}
                style={{ fontWeight: "600", color: email.email === email.confirmEmail  ? "#00f600" :"green" }}
              >
                Ticket
              </div>
              {/* <div
                className="bg-transparent text-primary border-0 text-start"
                style={{ fontWeight: "600" }}
              >
                {isMediumScreen && isLargeScreen ? "Booking" : null}
              </div> */}
            </div>
            {/* <OrderHeading /> */}
            <div className="text-white w-100">
              {/* {shoppingCartBasket.map((eachShoppingCartBasket, index) => ( */}

              <FormikProvider value={formik}>
                <FieldArray
                  name="shoppingCart"
                  render={(arrayHelpers: any) => (
                    <Fragment>
                      {formik.values.shoppingCart.map(
                        (values: any, index: number) => (
                          <div className="w-100">
                            <div className="mb-0 bg-transparent d-flex border-0 w-100">
                              <Col
                                className="bg-transparent text-white border-0 fs-13 pt-0 text-white"
                                style={{
                                  width: "2%",
                                  marginLeft: isMediumScreen ? "-20px" : "-5px",
                                  paddingLeft: isMediumScreen ? "" : "10px",
                                  paddingRight: isMediumScreen ? "" : "20px",
                                }}
                              >
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  <p className="p-0 fs-16" style={{color: arePrerequisitesFilled(index, values, email) ? "white" : "green"}}>
                                    {index + 1}
                                  </p>
                                </div>

                              </Col>


                              <Col
                                lg={11}
                                md={10}
                                sm={6}
                                style={{ marginLeft: 0 }}
                                className=""
                              >
                                <div className="bg-transparent border-0 text-start" style={{color: arePrerequisitesFilled(index, values, email) ? "white" : "green"}}>
                                  <p
                                    className={`${
                                      isMediumScreen ? "fs-14" : "fs-12"
                                    }  mb-0`}
                                    style={{ color: arePrerequisitesFilled(index, values, email) ? "white" : "green"}}
                                  >
                                    {/* {selectedStations.from} -&gt; {selectedStations.to} */}
                                    {ApiInstance.get_station_name(
                                      values.route.stiddep,
                                    )}{" "}
                                    -&gt;{" "}
                                    {ApiInstance.get_station_name(
                                      values.route.stidarr,
                                    )}
                                  </p>
                                  <p
                                    className={`${
                                      isMediumScreen ? "fs-14" : "fs-11"
                                    } mb-1 `}
                                    style={{ color: arePrerequisitesFilled(index, values, email) ? "white" : "green"}}
                                  >
                                    <span>
                                      {moment(
                                        new Date(values.route.leg[0].lxdate),
                                      ).format("dddd")}
                                      ,{" "}
                                      {moment(
                                        new Date(values.route.leg[0].lxdate),
                                      ).format("D")}
                                      <sup>
                                        {moment(
                                          new Date(values.route.leg[0].lxdate),
                                        )
                                          .format("Do")
                                          .replace(/\d+/g, "")}
                                      </sup>{" "}
                                      {moment(
                                        new Date(values.route.leg[0].lxdate),
                                      ).format("MMMM")}{" "}
                                      {moment(
                                        new Date(values.route.leg[0].lxdate),
                                      ).format("YYYY")}
                                    </span>
                                    {", "}
                                    {moment(
                                      values.route.leg[0].tod_dep,
                                      "HH:mm:ss",
                                    ).format("hh:mm A")}
                                    <span> -</span>{" "}
                                    {moment(
                                      values.route.leg[0].tod_arr,
                                      "HH:mm:ss",
                                    ).format("hh:mm A")}{" "}
                                    , Train No. {values.route.leg[0].lxid},
                                    <strong className="" style={{ color: arePrerequisitesFilled(index, values, email) ? "white" : "green"}}>
                                      {" "}
                                      Free Seating,
                                    </strong>
                                    <strong
                                      className=""
                                      style={{ paddingLeft: "10px", color: arePrerequisitesFilled(index, values, email) ? "white" : "green" }}
                                    >
                                      ₦
                                      {(
                                        values.centprice / 100 || 0
                                      ).toLocaleString(navigator.language, {
                                        minimumFractionDigits: 0,
                                      })}.00
                                    </strong>
                                  </p>
                                </div>
                                
                              </Col>

                            </div>
                            <Col  lg={12}
                                md={10}
                                sm={6} style={{marginLeft:"42px", width:"95%", margin:"auto"}}>
                            <Container
                                  style={{ marginLeft: "", width: "100%" }}
                                >
                                  <Row className="d-flex"  style={{width:"100%", margin: "auto", justifyContent:"space-between", marginBottom: isMediumScreen?"10px": "10px",}}>
                                    <Col
                                      lg={6}
                                      className="mb-2 text-center pt-0"  
                                    >
                                      <Form.Control
                                        type="text"
                                        id="firstName"
                                        // name="firstName"
                                        name={`shoppingCart.${index}.firstName`}
                                        placeholder="Please type in your first name"
                                        value={values.firstName}
                //                        onChange={ (e) => {formik.handleChange(e)
                //   handleInputChange(e.target.value, lastNameRef);
                // }}
                                      onChange={formik.handleChange}
                                        onBlur={(e) => {
                                          if (e.target.value !== "") {
                                            e.target.classList.add("activeBorder");
                                          } else {
                                            e.target.classList.add("border");
                                            e.target.classList.add("border-primary");
                                            e.target.classList.remove(
                                              "border");
                                          }
                                        }}
                                        // ref={firstNameRef}
                                        // onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
                                        isInvalid={!!formik.errors.firstName}
                                        style={{
                                          color:
                                            values.firstName !== ""
                                              ? "white"
                                              : "#00f600",
                                              marginLeft:isMediumScreen? "-30px": "",
                                              marginRight:""
                                        }}
                                        className={`"w-100 custom-input-placeholder form-input  ${
                                          values.firstName !== ""
                                            ? "activeBorder"
                                            : "border border-primary"
                                        }`}
                                      />
                                      {validate(index, "firstName") ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {
                                            formik.errors.shoppingCart[index]
                                              .firstName
                                          }
                                        </Form.Control.Feedback>
                                      ) : null}
                                    </Col>
                                    <Col
                                      lg={6}
                                      className="mb-2 text-center pt-0" style={{ marginRight:"-20px"}}
                                    >
                                      <Form.Control
                                        type="text"
                                        // id="lastName"
                                        id={`shoppingCart.${index}.lastName`}
                                        // name="lastName"
                                        name={`shoppingCart.${index}.lastName`}
                                        placeholder="Please type in your LastName"
                                        value={values.lastName}
                //                         onChange={ (e) => {formik.handleChange(e)
                //   handleInputChange(e.target.value, lastNameRef);
                // }}
                                         onChange={formik.handleChange}
                                        onBlur={(e) => {
                                          if (e.target.value !== "") {
                                            e.target.classList.add("activeBorder");
                                          } else {
                                            e.target.classList.add("border");
                                            e.target.classList.add("border-primary");
                                            e.target.classList.remove(
                                              "activeBorder");
                                          }
                                        }}
                                        // 
                                        isInvalid={!!formik.errors.lastName}
                                        style={{
                                          color:
                                            values.lastName !== ""
                                              ? "white"
                                              : "#00f600",
                                        }}
                                        className={`"w-100 custom-input-placeholder form-input ${
                                          values.lastName !== ""
                                            ? "activeBorder"
                                            : "border border-primary"
                                        }`}
                                      />
                                      {validate(index, "lastName") ? (
                                        <Form.Control.Feedback
                                          type="invalid"
                                          className="d-block"
                                        >
                                          {
                                            formik.errors.shoppingCart[index]
                                              .lastName
                                          }
                                        </Form.Control.Feedback>
                                      ) : null}
                                    </Col>
                                  </Row>
                                  {/* <Row className="w-100">
                                    {index === 0 ? (
                                      <>
                                        <Col
                                          lg={5}
                                          className="mb-2 text-center pt-0"
                                        >
                                          <Form.Control
                                            type="text"
                                            // id="email"
                                            id={`shoppingCart.${index}.email`}
                                            // name="email"
                                            name={`shoppingCart.${index}.email`}
                                            placeholder="Please type in your email"
                                            value={values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`"w-100 custom-input-placeholder border ${values.email !== "" ? "" : "border-primary"}`}
                                            isInvalid={!!formik.errors.email}
                                            style={{ color: values.email !== "" ? "white" : "" }}
                                          />
                                          {validate(index, "email") ? (
                                            <Form.Control.Feedback
                                              type="invalid"
                                              className="d-block"
                                            >
                                              {
                                                formik.errors.shoppingCart[
                                                  index
                                                ].email
                                              }
                                            </Form.Control.Feedback>
                                          ) : null}
                                        </Col>
                                        <Col
                                          lg={5}
                                          className="mb-2 text-center pt-0"
                                        >
                                          <Form.Control
                                            type="text"
                                            // id="confirmEmail"
                                            id={`shoppingCart.${index}.confirmEmail`}
                                            // name="confirmEmail"
                                            name={`shoppingCart.${index}.confirmEmail`}
                                            placeholder="Please confirm your Email"
                                            value={values.confirmEmail}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className={`"w-100 custom-input-placeholder border ${values.confirmEmail !== "" ? "" : "border-primary"}`}
                                            isInvalid={
                                              !!formik.errors.confirmEmail
                                            }
                                            style={{ color: values.confirmEmail !== "" ? "white" : "" }}
                                          />
                                          {validate(index, "confirmEmail") ? (
                                            <Form.Control.Feedback
                                              type="invalid"
                                              className="d-block"
                                            >
                                              {
                                                formik.errors.shoppingCart[
                                                  index
                                                ].confirmEmail
                                              }
                                            </Form.Control.Feedback>
                                          ) : null}
                                        </Col>
                                      </>
                                    ) : null}
                                  </Row> */}
                                </Container>
                            </Col>
                          </div>
                        ),
                      
                      )}
                    </Fragment>
                  )}
                />
              </FormikProvider>
            </div>
          <div className={`${isMediumScreen? "" : "fs-14" } text-center text-primary`}>Only one ticket will be issued per passenger and trip.</div> 
          </Form>
        </Col>
        </SimpleBar >
      </Col>
        <div>{/* PurchaseWithoutRegisteringForm Data */}</div>
      
      <div>
        
      </div>
      <GoButton
        disabled={!formik.isValid || !isValid}
        disabledNextButton
        onClickNextButton={() => {}}
        onClick={formik.handleSubmit}
        // onClick={formik.onSubmit}
        onClickBackButton={() => navigate("/shopping-cart")}
        showGroupButtons
      />
    </Fragment>
  );
};

export default PurchaseWithoutRegisteringForm;
