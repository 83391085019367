import { matchRoutes, useLocation } from "react-router-dom";
import TextsComponent from "../../component/TextsComponent/TextsComponent";
import Layout from "../../Layout";

const Information = () => {
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/information" }];
  const matches = matchRoutes(someRoutes, currentLocation);

  return (
    <Layout>
      <TextsComponent
        title="Information"
        texts={[
          "Welcome to the official Port Harcourt - Aba e-ticketing platform provided by the Nigerian Railway Corporation in cooperation with its technical partners.",
          "We are constantly working to improve our service for you and will therefore update this platform on regular intervals.",
          "Please note that this application features free seating only on all journeys due to NRC's policy.",
          "For more information please visit the official NRC website <a href='https://nrc.gov.ng' target='_blank' rel='noopener noreferrer'>https://nrc.gov.ng</a>.",
          "We hope you will enjoy a smooth booking process with this application and wish you a pleasant trip.",
        ]}
        show={!!matches}
        isEmpty
      />
      
    </Layout>
  );
};

export default Information;
