import { toast } from "react-toastify";
import { IRouteConnection, IRouteLeg } from "./models/route.model";
import {
  IAddRouteOption,
  IAutomaticReservationsContinuation,
  IComputeRoutePayload,
  IContinuation,
  IDispatchMethods,
  IDispatchType,
  IEmptyNumber,
  IEmptyString,
  ILxAux,
  IOnAddTicket,
  IOnDelTicket,
  IOnError,
  IOnInit,
  IOnMessagePriceListResponse,
  IOnProductsReady,
  IOnRouteFinished,
  IOnSessionExpired,
  IOnShoppingBasketChanged,
  IOnStartPayment,
  IOnStationsChanged,
  IOnTrainRunReservationChange,
  IOnWaiting,
  ISendQueue,
  IStAux,
  IAddTicketPayload,
  ITicketPayload,
  ITicketPrice,
  IWaitingFor,
  IOnLastPurchase,
  IOnPurchase,
  IOnReservationListReady,
  IComputeRouteResponse,
  IShoppingBasketResponse,
  IShoppingBasketData,
  IStartPaymentResponse,
  IOnPaymentChanged,
  IPurchaseResponse,
} from "./models/txticket_api.model";

class TxticketApi {
  url: IEmptyString = null;
  ws: null | WebSocket = null;
  ws_failcount: number = 0;
  max_fail: number = 17;
  send_queue: ISendQueue[] = [];
  last_request_id: number = 0;
  waiting_for: IWaitingFor = {};
  delayed_wait_notification: null | NodeJS.Timeout = null;
  station_name_cache: string[] = [];
  sessionState: null | string = null;
  shopping_basket: null | IShoppingBasketData[] = null;
  own_reservationlist: any[] = [];
  seating_scheme: any = {};
  fetch_n_routes: number = 20;
  last_pmtref: null | string = null;
  last_payment_data: null | { state: "PAYING"; pmtref: string } = null;
  routing_trip_limit_s: number = 604800;
  routing_reduce_xfer_s: number = 0;
  compute_route_continuation: IContinuation | null = null;
  automatic_reservations_continuation: null | IAutomaticReservationsContinuation =
    null;
  automatic_reservations_retry_delay: number = 1000; // 1 second
  automatic_reservations_max_retries: number = 3;
  automatic_reservations_retry_count: number = 0;

  current_stid_origin: IEmptyNumber = null;
  current_stid_destination: IEmptyNumber = null;
  current_input_date: IEmptyString = null;

  routes: IRouteConnection[] = [];
  line_service_cache: ILxAux | IStAux = {};
  last_route_id: number | null = null;

  produce_price: ITicketPrice[] = [];

  on_routing_finished: null | IOnRouteFinished = null;
  on_products_ready: null | IOnProductsReady = null;
  on_add_ticket: null | IOnAddTicket = null;
  on_del_ticket: null | IOnDelTicket = null;
  on_start_payment: null | IOnStartPayment = null;
  on_purchase: null | IOnPurchase = null;
  on_reservationlist_ready: null | IOnReservationListReady = null;
  on_last_purchase: null | IOnLastPurchase = null;

  station_list_initialized = false;
  notify_shopping_basket_on_station_list = false;
  on_init_sent = false;

  // notification on stations changed
  // called whenever the list of available station is filled from the server
  // or changes. After this callback is fired, get_stations() will return
  // a non-null result
  on_stations_changed: null | IOnStationsChanged = null;

  on_payment_changed: null | IOnPaymentChanged = null;

  // called whenever the shopping basket has been changed
  on_shopping_basket_changed: null | IOnShoppingBasketChanged = null;

  // called whenever a trainrun message is received
  on_trainrun_reservation_change: null | IOnTrainRunReservationChange = null;

  // session expired on server
  // an information should be displayed to the user and the page
  // should be reloaded/reset
  on_session_expired: null | IOnSessionExpired = null;

  // txticket service initialized. Called when the current session
  // state and shopping basked have been set up.
  on_init: null | IOnInit = null;

  // called whenever the wait state changes, i.e. there are pending
  // api transactions
  on_waiting: null | IOnWaiting = null;

  // Called whenever a server error is reported on the websocket.
  // The handler function is passed an object with the following members:
  // error: The error message
  // method: [optional] the websocket method which triggered the error
  // is: [optional] the websocket request number which triggered the error
  on_error: null | IOnError = null;

  // initalize api class and connect web socket
  constructor(websocket_url: string) {
    this.url = websocket_url;
    this.connect();
  }

  /* get the current session state. Returns a string with one of
    the following values:

    # SHOPPING:
    #   The basic session state in which the user fills the shopping cart.
    #   Changes can be made at any time.
    #
    # AUTOMATIC_RESERVATIONS:
    #   The session state where outstanding reservations are acquired.
    #   This state should happen between SHOPPING and PAYING.
    #   Without all reservations being fulfilled, it is not allowed to
    #   enter the state PAYING or PAYING_GWACTIVATE or PAYING_WEBACTIVATE.
    #   Currently (2021-07-31) not implemented. Automatic reservations
    #   are only acquired in a single-try one-shot operation.
    #
    # PAYING_GWACTIVATE:
    #   Like PAYING but the webserver must wait for the payment gateway
    #   to activate the payment. Once the gateway has activated the payment,
    #   the session state changes to PAYING.
    #
    # PAYING_WEBACTIVATE:
    #   Like PAYING but the web browser must activate the payment first.
    #   Once the browser has activated the payment, the session state
    #   changes to PAYING_SETACTIVATION.
    #
    # PAYING_SETACTIVATED:
    #   Like PAYING but the web server must first perform the activation
    #   of the payment before the session can proceed. Once the activation
    #   is done by the web server, the session state changes to PAYING.
    #
    # PAYING:
    #   The session state where the user pays for the shopping cart.
    #   The shopping cart is locked; the user cannot make changes
    #   to the shopping cart while state PAYING is in progress.
    #
    # PAYING_CANCELLED:
    #   State entered from PAYING when the user cancels a payment
    #   process. In this state, the shopping cart is still locked
    #   and the customerweb server will automatically expire the
    #   payment record in the database.
    #   After expiry, the customerweb server will set the state back
    #   to SHOPPING.
    #
    # PAYING_ALLEGED:
    #   State entered from PAYING when the user completes a payment
    #   process successfully and the web browser sends a (provisionary)
    #   confirmation. This state is basically similar to 'PAYING'
    #   except that a later cancellation by the user will not apply
    #   and that the server may apply different timeouts while waiting
    #   for the decisive message from the payment provider
    #   that triggers the state change of the payment to 'CONFIRMED'.
    #   Note that in this state, payment has not been confirmed by the
    #   payment provider, only by the web browser.
    #
    # PURCHASING:
    #   The session state where the payment has been completed and
    #   confirmation has been received from the payment provider via
    #   the back channel.
    #   In this state, the customerweb will acquire the tickets from the
    #   mobile unit.
    #
    # STORING:
    #   The session state where the purchased tickets have been received
    #   from the mobile unit and are temporarily stored in the session.
    #   In this state, the purchased tickets will be stored in the
    #   customer database.
    #
    # PURCHASED:
    #   The final session state where the tickets have been acquired
    #   and stored in the customer database.
    #   The state is responsible for sending the tickets to the customer.
    */
  getSessionState() {
    return this.sessionState;
  }

  // returns an array of all available (active) stations
  // the index in the array coresponds to the "stid" (station id)
  get_stations() {
    return this.station_name_cache;
  }

  // returns the name of a station by station id
  get_station_name(stid: number) {
    console.assert(Number.isInteger(stid), "station id must be an integer");
    console.assert(stid > 0, "station id must be greater than zero");

    return this.station_name_cache[stid];
  }

  // set the "from"-station ID
  set_stid_origin(stid: number) {
    console.assert(Number.isInteger(stid), "station id must be an interger");
    console.assert(stid > 0, "station id must be greater than zero");

    if (stid === this.current_stid_origin) return;
    this.send({ method: "set_stidorg", stidorg: stid });
    this.current_stid_origin = stid;
  }

  // set the "to"-station ID
  set_stid_destination(stid: number) {
    console.assert(Number.isInteger(stid), "station id must be an interger");
    console.assert(stid > 0, "station id must be greater than zero");

    if (stid === this.current_stid_destination) return;
    this.send({ method: "set_stiddst", stiddst: stid });
    this.current_stid_destination = stid;
  }

  get_stid_origin() {
    return this.current_stid_origin;
  }

  get_stid_destination() {
    return this.current_stid_destination;
  }

  // set departure date in iso format 'YYYY-MM-DD'
  set_input_date(dt: string) {
    console.assert(
      dt.length === 10 && dt[4] === "-" && dt[7] === "-",
      "date must be YYYY-MM-DD formatted"
    );
    if (dt === this.current_input_date) return;
    this.send({ method: "set_input_date", input_date: dt });
    this.current_input_date = dt;
  }

  // set first_name in ticket
  set_pax_given_name(biunique: number, pax_given_name: string) {
    console.assert(
      `biunique: ${biunique}, pax_given_name: ${pax_given_name}`,
      "date must be YYYY-MM-DD formatted"
    );
    if (!biunique || !pax_given_name) return;
    this.send({ method: "set_pax_given_name", biunique, pax_given_name });
  }

  // set surname in ticket
  set_pax_surname(biunique: number, pax_surname: string) {
    console.assert(
      `biunique: ${biunique}, pax_surname: ${pax_surname}`,
      "date must be YYYY-MM-DD formatted"
    );
    if (!biunique || !pax_surname) return;
    this.send({ method: "set_pax_surname", biunique, pax_surname });
  }

  // set NIN in ticket: 
  // set_pax_nin_id(biunique: number, pax_id: string) {
  //   console.assert(
  //     `biunique: ${biunique}, pax_id: ${pax_id}`,
  //     "date must be YYYY-MM-DD formatted"
  //   );
  //   if (!biunique || !pax_id) return;
  //   this.send({ method: "set_pax_id", biunique, pax_id });
  // }

  get_input_date() {
    return this.current_input_date;
  }

  // returns the payment reference of an ongoing payment or null if
  // no payment has been started yet
  get_last_pmtref() {
    return this.last_pmtref;
  }

  get_last_payment_data() {
    return this.last_payment_data;
  }

  // returns an array of all items in the current shopping basket
  get_shopping_basket() {
    return this.shopping_basket;
  }

  // returns the total monetary amount of all items in the current shopping basket
  get_shopping_basket_amount() {
    let amount_total = 0;
    if (this.shopping_basket != null) {
      for (let item of this.shopping_basket) {
        amount_total += item.centprice;
      }
    }
    return amount_total;
  }

  // returns information on a line service
  get_line_service(lxid: number) {
    console.assert(
      Number.isInteger(lxid),
      "line service ID mist be an integer"
    );
    console.assert(lxid > 0, "line service ID mist be greater than zero");

    return this.line_service_cache[lxid];
  }

  // sets the customer's email address used for the payment process
  set_payment_email(email: string) {
    let s = JSON.stringify(email);
    window.localStorage.setItem("payment_email", s);
    console.log("TxticketApi: saved payment_email: " + email);
  }

  get_payment_email() {
    let email = "";
    let s = window.localStorage.getItem("payment_email");
    if (s) {
      try {
        email = JSON.parse(s);
      } catch (e) {
        console.error("TxticketApi: payment_email storage format error");
      }
    }
    console.log("TxticketApi: retrieved payment email: " + email);
    return email;
  }

  // returns a list of all routes obtained during the last routing process,
  // i.e. the last call to start_routing()
  get_routes() {
    return this.routes;
  }

  // returns true, if routing is in progress (i.e. between the call to
  // start_routing and when the routing_finished callback is executed).
  is_routing() {
    return this.on_routing_finished != null;
  }

  // aborts an active routing process.
  // After a call to abort_routing no more routes will be retrieved and the
  // routing_finished callback won't be executed
  abort_routing() {
    console.assert(
      !this.on_routing_finished,
      "abort_routing() called, but routing not in progress"
    );

    this.on_routing_finished = null;
    this.routes = [];
    this.del_wait_for("routes");
  }

  // start the oruting process
  // returns all possible connection of the selected day via the
  // add_route_option() callback
  start_routing(
    onRoutingFinished: IOnRouteFinished,
    addRouteOption: IAddRouteOption
  ) {
    console.assert(
      onRoutingFinished != null,
      "start_routing() called, but previous routing process noy yet finished"
    );
    console.assert(
      (this.current_stid_origin as number) > 0,
      "start_routing() called, but origin has not yet been set"
    );
    console.assert(
      (this.current_stid_destination as number) > 0,
      "start_routing() called, but destination has not yet been set"
    );
    console.assert(
      this.current_stid_origin !== this.current_stid_destination,
      "start_routing() called, but origin and destination are the same"
    );
    console.assert(
      this.current_input_date != null,
      "start_routing() called, but departure date has not yet been set"
    );

    if (this.on_routing_finished) {
      console.warn("TxticketApi: previous routing not finished");
    }
    this.on_routing_finished = onRoutingFinished;

    let stidorg = this.current_stid_origin;
    let stiddst = this.current_stid_destination;

    let st_org = this.get_station_name(stidorg as number);
    let st_dst = this.get_station_name(stiddst as number);

    if (!st_org || !st_dst) {
      console.error("TxticketApi: routing: stations not valid");
      this.routing_finished();
      return;
    }

    let refdate = this.current_input_date;
    if (refdate == null) {
      console.error("TxticketApi: routing: date not set");
      return;
    }
    let reftod = "00:00:00";

    let today = new Date();
    if (this.to_date_string(today) === refdate) {
      reftod = this.to_time_string(today);
    }

    if (!this.check_date(refdate)) {
      console.error("TxticketApi: routing: date not valid");
      this.routing_finished();
      return;
    }

    this.add_wait_for("routes");
    this.routes = [];

    this.compute_route(
      refdate,
      reftod,
      null,
      true,
      this.fetch_n_routes,
      (route_data) => {
        this.on_compute_route_finished(route_data, addRouteOption);
      }
    );
  }

  // compute one or more routes
  // either refdate and reftod, or refroute must be non-null
  //
  compute_route(
    refdate: string | null,
    reftod: string | null,
    refroute: IRouteConnection | string | null,
    forward: boolean,
    count: number,
    continuation: IContinuation
  ) {
    console.assert(
      (refdate !== null && reftod !== null && refroute === null) ||
        (refdate === null && reftod === null && refroute !== null),
      "compute_route(): either refdate and reftod or refroute have to be set"
    );

    console.assert(this.compute_route_continuation === null);
    this.compute_route_continuation = continuation;

    let id = ++this.last_request_id;
    this.last_route_id = id;

    let stidorg = this.current_stid_origin as number;
    let stiddst = this.current_stid_destination as number;

    console.assert(stidorg > 0);
    console.assert(stiddst > 0);

    /* var parameters = { 'stidorg': stidorg,
                           'stiddst': stiddst,
                           'refdate': refdate,
                           'reftod': reftod };
        this.routelist.parameters = parameters; */
    let request: IComputeRoutePayload = {
      method: "compute_route",
      stidorg: stidorg,
      stiddst: stiddst,
      forward: forward,
      compact: true,
      count: count,
      trip_limit_s: this.routing_trip_limit_s,
      reduce_xfer_s: this.routing_reduce_xfer_s,
      id: id,
    };
    if (refdate !== null) request.refdate = refdate;
    if (reftod !== null) request.reftod = reftod;
    if (refroute !== null) request.refroute = refroute;

    this.send(request);
  }

  // Compare two routing legs: legs of routes
  compare_routing_leg(
    a: IRouteLeg,
    b: IRouteLeg // -1,0,1 if a <,==,> b
  ) {
    // Compare departure date (forward order)
    if (a.date_dep !== b.date_dep) return a.date_dep < b.date_dep ? -1 : 1;
    if (a.tod_dep !== b.tod_dep) return a.tod_dep < b.tod_dep ? -1 : 1;

    // Compare arrival date (reverse order)
    if (a.date_arr !== b.date_arr) return a.date_arr < b.date_arr ? 1 : -1;
    if (a.tod_arr !== b.tod_arr) return a.tod_arr < b.tod_arr ? 1 : -1;

    // Compare lines, stations, base dates
    if (a.lxdate !== b.lxdate) return a.lxdate < b.lxdate ? -1 : 1;
    if (a.lxid !== b.lxid) return a.lxid < b.lxid ? -1 : 1;
    if (a.stid_dep !== b.stid_dep) return a.stid_dep < b.stid_dep ? -1 : 1;
    if (a.stid_arr !== b.stid_arr) return a.stid_arr < b.stid_arr ? -1 : 1;
    if (a.occurrence_dep !== b.occurrence_dep)
      return a.occurrence_dep < b.occurrence_dep ? -1 : 1;
    if (a.occurrence_arr !== b.occurrence_arr)
      return a.occurrence_arr < b.occurrence_arr ? -1 : 1;

    return 0;
  }

  // returns a list of product available on a given route
  get_products_and_prices(
    route: undefined | IRouteConnection,
    continuation: IOnProductsReady
  ) {
    if (this.on_products_ready) {
      console.warn(
        "TxticketApi: previous call to get_products_and_prices not finished"
      );
    }
    this.on_products_ready = continuation;

    let group_pax = 0;
    this.produce_price = [];
    let id = ++this.last_request_id;

    this.send({
      method: "get_product_prices",
      group_pax: group_pax,
      route: route,
      // 'prid': MY_CONSTANT_PRID,
      id: id,
    });

    this.add_wait_for("product_prices");
  }

  // add a ticket to the shopping basket
  add_ticket(params: IAddTicketPayload, continuation: IOnAddTicket) {
    if (this.on_add_ticket)
      console.error("TxticketApi: add_ticket: already adding a ticket");

    this.on_add_ticket = continuation;

    const { prid, pvid, route, centprice } = params;

    let request: ITicketPayload = {
      method: "add_ticket",
      id: ++this.last_request_id,
      prid,
      pvid,
      route,
      centprice,
    };
    // for (let p of ["prid", "pvid", "route", "centprice"]) {
    //   request[p] = params[p];
    // }

    this.add_wait_for("shopping_basket");
    this.send(request);
  }

  // remove a ticket from the shopping basket
  del_ticket(biunique: number, continuation: IOnDelTicket) {
    if (this.on_del_ticket)
      console.error("TxticketApi: del_ticket: already deleting a ticket");

    this.on_del_ticket = continuation;

    let request = {
      method: "del_ticket",
      biunique: biunique,
      id: ++this.last_request_id,
    };

    this.add_wait_for("shopping_basket");
    this.send(request);
  }

  // explicitely request an update of the shopping basket from the server.
  // This is useful if reservations have changed. (The server doesn't send
  // updates on the shopping basket if there have been just changes in the
  // reservation state).
  reload_shopping_basket() {
    this.load_shopping_basket();
  }

  // Purge the shopping basket
  clear_shopping_basket() {
    // let n =  this.shopping_basket.length;
    const id = ++this.last_request_id;
    const request = { method: "clear_shopping_basket", id: id };
    this.add_wait_for("shopping_basket");
    this.send(request);
  }

  // subscribe a specific train run
  // changes on this trainrun will be delivered via the
  // on_trainrun_reservation_change callback
  get_trainrun(lxid: number, lxdate: string) {
    console.assert(
      Number.isInteger(lxid),
      "get_trainrun(): lxid must be numeric"
    );
    console.assert(lxid > 0, "get_trainrun(): lxid must be a positive integer");

    const request = { method: "get_trainrun", lxid: lxid, lxdate: lxdate };
    this.send(request);
  }

  // get a list of reservations belonging to the current shopping basket
  get_own_reservations(continuation: IOnReservationListReady) {
    if (this.on_reservationlist_ready) {
      console.warn(
        "TxticketApi: previous call to get_reservationlist not finished"
      );
    }
    this.on_reservationlist_ready = continuation;
    let request = { method: "get_reservation" };
    this.send(request);
  }

  // ask the server to perform automatic reservations for all tickets in
  // the current shopping basket
  automatic_reservations_onetry() {
    const id = ++this.last_request_id;
    this.send({ method: "automatic_reservations_onetry", id: id });
  }

  // count the number of seats, which do not have an associated reservation
  num_reservations_missing() {
    let n_reservations = 0;
    let n_reservations_done = 0;

    let shopping_basket = this.shopping_basket;

    if (shopping_basket) {
      for (let item of shopping_basket) {
        if (item.pv.with_reservations)
          n_reservations += item.count * item.route.leg.length;
        if (!("rv" in item)) continue;
        for (let rvl of item.rv) {
          for (let s of rvl) {
            if (s > 0) n_reservations_done++;
          }
        }
      }
    }

    let n_reservations_missing = n_reservations - n_reservations_done;
    console.log(
      "TxtickatApi: reservations_missing: " +
        n_reservations_missing +
        " (out of " +
        n_reservations +
        ")"
    );
    return n_reservations_missing;
  }

  // Do automatic reservations.
  // Retries reservations several times, on success or on timeout the
  // continuation gets called.
  //
  // Example:
  // automatic_reservations((ok) =>
  //     {
  //         if (ok)
  //             console.debug("success: got reservations for all tickets");
  //         else
  //             alert("The train is fully booked, please change another train or class");
  //     } );
  automatic_reservations(continuation: IAutomaticReservationsContinuation) {
    console.assert(
      this.automatic_reservations_continuation === null,
      "TxticketApi: automatic_reservations() called, but automatic reservation process is already in progress"
    );

    this.automatic_reservations_continuation = continuation;
    this.automatic_reservations_retry_count = 0;

    this.automatic_reservations_onetry();

    setTimeout(() => {
      this.automatic_reservations_retry();
    }, this.automatic_reservations_retry_delay);
  }

  // get information a a specific seat in a given seating scheme
  get_seat_info(schemeid: number, seat_number: number) {
    for (const s of this.seating_scheme[schemeid].seatlist) {
      if (s[0] === seat_number) return s[3];
    }
    return null;
  }

  // associte a passenger ID with a ticket
  set_pax_id(biunique: number, pax_id: number) {
    this.send({
      method: "set_pax_id",
      biunique: biunique,
      pax_id: pax_id,
    });
  }

  // start the payment process.
  // Parameters:
  //  pgwname: name of the payment gateway (payment service provider).
  //    It has to matche one of the payment gateways preconfigured on
  //    the server.
  //  email: the customer's email address
  //  text: A text string, summarizing the payment. The email and
  //    the text will be passed on to the payment service provider.
  //  continuation: A callback function, which gets an object with the
  //    following elements:
  //      pgwname: name of the payment gateway (same as above)
  //      pmtid: payment id
  //      pmsid: payment state id
  //      pmtref: payment reference number issued by the server. Pass this
  //        string to the payment service provide. This is the primary key
  //        used to identify the payment later on.
  //      pmtauth: payment reference number issued by the payment service
  //        provider (empty, when the payment has just been created).
  //        email: customer's email address
  //      cuid: customer ID, if registerd customer or null if guest
  //      tid: txticket database transaction id
  //      text: The text string, summarizing the payment
  //      amount_cent: amout invoiced (in the smallest monetary unit)
  //      jsondata: optional object with additional data associated to the payment
  //
  start_payment(
    pgwname: string,
    email: string,
    text: string,
    continuation: IOnStartPayment
  ) {
    if (this.on_start_payment)
      console.error("TxticketApi: start_payment: payment already in progress");

    this.on_start_payment = continuation;

    let amount_cent = this.get_shopping_basket_amount();
    console.log(
      "TxticketApi: sending start_payment pgwname: " +
        pgwname +
        " amount_cent: " +
        amount_cent +
        " email: " +
        email
    );
    if (this.sessionState === "PAYING") {
      this.send({ method: "resume_last_payment" });
    } else {
      const id = ++this.last_request_id;
      this.send({
        method: "start_payment",
        id: id,
        amount_cent: amount_cent,
        pgwname: pgwname,
        text: text,
        email: email,
      });
    }
  }

  // call this function, when the payment process with the payment service
  // provider has been completed successfully
  //
  // paramters:
  //   pgwname: payment gateway name, same as in function start_payment()
  //   pmtref: payment reference number (obtained from the contiunuation
  //     callback of start_oayment).
  //   pmtauth: payment reference number issued by the payment service
  //     provider
  //   response: javascript object containing additional information of the
  //     the payment from the payment service provider (such as masked card
  //     number, etc.)
  //   continuation: a callback function, which gets executed when the
  //     server has verified the payment
  confirm_payment(
    pgwname: string,
    pmtref: string | number,
    pmtauth: string,
    response: any,
    continuation: IOnPurchase
  ) {
    if (this.on_purchase)
      console.error("TxticketApi: payment_done: payment already in progress");

    this.on_purchase = continuation;

    const id = ++this.last_request_id;
    this.send({
      method: "payment_done",
      id: id,
      pmtref: pmtref,
      pgwname: pgwname,
      pmtauth: pmtauth,
      response: response,
    });
    this.add_wait_for("purchase");
    this.add_wait_for("shopping_basket");
  }

  // cancel an ongoing payment
  cancel_payment(pgwname: string, pmtref: string | number) {
    const id = ++this.last_request_id;
    this.send({
      method: "cancel_payment",
      id: id,
      pmtref: pmtref,
      pgwname: pgwname,
    });
  }

  // When a ticket has been purchased, use this function to
  // get the ticket data.
  //
  // Call like this:
  //
  // api.get_last_purchase( (purchase) =>
  //     {
  //          console.log("got tickets: ", purchase.ticketlist);
  //     }
  // );
  //
  // The data will be passed to the contiunation function and looks like
  // this:
  //   {
  //     "purchaseid": "purchase.07cd303e1e51b96e9bbad52decf9ee63.1",
  //     "purchasetime": "2024-04-22T16:49:57",
  //     "expiry": "2024-04-23",
  //     "ticketlist": [
  //     {
  //       "ryid_uq": 2, "uqs": 1, "uqi": 205, "ibi": 1, "muid": 2,
  //       "time_created": "2024-04-22T16:49:57",
  //       "tno": 33994815628, "tnocode": 939376365, "tnocodechar": "LNY9AM",
  //       "ticket_hash": "c87d62e09ef786.....2fc4ad158ad59eb65e87ff6bff",
  //       "checksum": 428557,
  //       "url": "HTTPS://NRC.TXWARE.COM/T/209Q5W8:3N3EQ3.....",
  //       "tnodata": { ... },
  //       "asid": 1, "asid_tag": "ACTIVE",
  //       "inception": "2024-04-23", "tinception": "2024-04-23", "vinception": "2024-04-23",
  //       "expiry": "2024-04-23", "texpiry": "2024-04-23", "vexpiry": "2024-04-23",
  //       "muexpiry": "2024-04-23",
  //       "pvid": 2, "prid": 2, "pr_name": "Single Trip 2nd Class",
  //       "pr_data": { ... },
  //       "pv_pcid": 1, "pv_is_season": false, "pv_is_group": false,
  //       "pv_needs_stations": true, "pv_with_reservations": true,
  //       "pv_reservation_class": 2,
  //       "pv_data": { ... }, "pv_title": "Single Trip 2nd Class",
  //       "pcid": 1, "pc_name": "transportation", "pc_kind": 3,
  //       "external_unit": "", "external_unit_div": 1,
  //       "stid1": 1, "stid1_name": "ABA", "stid1_data": { ... },
  //       "stid2": 9, "stid2_name": "D/LINE", "stid2_data": { ... },
  //       "centprice": 20000,
  //       "historic": false,
  //       "n_rv": 1,
  //       "rvlist": [ .... ],
  //       "qrcode": "<?xml><svg> .... data ....</svg>"
  //     } ]
  //   }
  //
  get_last_purchase(continuation: IOnLastPurchase) {
    console.assert(
      this.on_last_purchase === null,
      "previous call to get_last_purchase is not finished yet"
    );
    this.on_last_purchase = continuation;
    this.send({ method: "get_last_purchase" });
  }

  purchase_stored(purchaseid: string | number) {
    this.send({
      method: "purchase_stored",
      purchaseid: purchaseid,
    });
  }

  on_compute_route_finished(
    routes: IRouteConnection[],
    add_route_option: IAddRouteOption
  ) {
    // check if cancelled
    if (this.on_routing_finished === null) return;

    for (let route of routes) {
      if (!("leg" in route) || route.leg.length < 1) {
        console.error("TxticketApi: got bad routing data: ", route);
        this.routing_finished();
        return;
      }

      var date_dep = route.leg[0].date_dep;
      var refdate = this.current_input_date;
      if (date_dep !== refdate) {
        this.routing_finished();
        return;
      }

      let i = this.routes.length;
      this.routes.push(route);

      if (add_route_option) add_route_option(route, i);
    }
    this.fetch_next_route(add_route_option);
  }

  fetch_next_route(add_route_option: IAddRouteOption) {
    console.log("TxticketApi: fetch_next_route");

    let n_routes = this.routes.length;
    // Only allow next route when an initial route is present
    if (n_routes < 1) {
      console.error("TxticketApi: fetch_next_route() called without a route");
      return;
    }

    let last_route = this.routes[n_routes - 1];
    this.compute_route(
      null,
      null,
      last_route,
      true,
      this.fetch_n_routes,
      (route_data) => {
        this.on_compute_route_finished(route_data, add_route_option);
      }
    );
  }

  routing_finished() {
    this.del_wait_for("routes");
    let continuation = this.on_routing_finished;
    this.on_routing_finished = null;
    if (continuation) continuation();
  }

  to_date_string(date: Date) {
    var y = date.getFullYear();
    var m = 1 + date.getMonth();
    var d = date.getDate();
    return y + "-" + (m >= 10 ? m : "0" + m) + "-" + (d >= 10 ? d : "0" + d);
  }

  to_time_string(d: Date) {
    var h = d.getHours();
    var m = d.getMinutes();
    var s = d.getSeconds();
    return (
      (h >= 10 ? h : "0" + h) +
      ":" +
      (m >= 10 ? m : "0" + m) +
      ":" +
      (s >= 10 ? s : "0" + s)
    );
  }

  automatic_reservations_retry() {
    if (this.automatic_reservations_continuation === null) return;

    if (
      this.automatic_reservations_retry_count >=
      this.automatic_reservations_max_retries
    ) {
      let continuation = this.automatic_reservations_continuation;
      this.automatic_reservations_continuation = null;
      continuation(false);
      return;
    }

    this.automatic_reservations_retry_count++;

    this.automatic_reservations_onetry();

    setTimeout(() => {
      this.automatic_reservations_retry();
    }, this.automatic_reservations_retry_delay);
  }

  check_reservations_ready() {
    if (this.automatic_reservations_continuation === null) return;

    if (this.num_reservations_missing() !== 0) return;

    let continuation = this.automatic_reservations_continuation;
    this.automatic_reservations_continuation = null;
    continuation(true);
  }

  // generic send method
  send(msg: ISendQueue) {
    this.send_queue.push(msg);
    this.send_first();
  }

  send_first() {
    // transient states, do nothing, try sending later
    if (
      this.ws?.readyState === WebSocket.CONNECTING ||
      this.ws?.readyState === WebSocket.CLOSING
    )
      return;

    if (this.ws?.readyState === WebSocket.CLOSED) {
      this.ws_failcount += 1;
      if (this.ws_failcount > this.max_fail) {
        console.error(
          "TxticketApi: WebSocket failing permanently, giving up ..."
        );
        // FIXME: inform the user about connection error?
        return;
      }
      console.log("TxticketApi: WebSocket closed, trying to reopen ...");
      let timeout = 50 * 1.35 ** this.ws_failcount;
      setTimeout(() => {
        this.connect();
      }, timeout);
      return;
    }

    let msg = this.send_queue.shift();
    console.log("TxticketApi: ws <<<<", msg);
    if (this.ws) this.ws.send(JSON.stringify(msg));

    if (this.send_queue.length > 0) this.send_first();
  }

  connect() {
    console.log("TxticketApi: sessionws_connect " + this.url);
    this.add_wait_for("connect");
    this.ws = new WebSocket(this.url as string);
    this.ws.onopen = (ev) => {
      this.on_ws_open(ev);
    };
    this.ws.onmessage = (ev) => {
      this.on_ws_message(ev);
    };
    this.ws.onclose = (ev) => {
      this.on_ws_close(ev);
    };
    this.ws.onerror = (ev) => {
      this.on_ws_error(ev);
    };
  }

  on_ws_open(evt: any) {
    console.log("TxticketApi: on_sessionws_open");
    this.del_wait_for("connect");

    if (
      this.station_name_cache.length === 0 &&
      !this.is_sending_method("list_stations")
    ) {
      if (this.waiting_for["stationlist"]) this.del_wait_for("stationlist");
      this.load_stations();
    }

    if (!this.is_sending_method("shopping_basket")) {
      if (this.waiting_for["shopping_basket"])
        this.del_wait_for("shopping_basket");
      this.load_shopping_basket();
    }

    if (this.send_queue.length > 0) this.send_first();
  }

  on_ws_close(evt: any) {
    console.log("TxticketApi: on_ws_close");

    // if there is something to to, try reconnect
    if (
      this.send_queue.length > 0 ||
      Object.keys(this.waiting_for).length > 0
    ) {
      this.ws_failcount += 1;
      if (this.ws_failcount > this.max_fail) {
        console.error(
          "TxticketApi: WebSocket failing permanently, giving up ..."
        );
        // FIXME: inform the user about connection error?
        return;
      }
      console.log("TxticketApi: WebSocket closed, trying to reopen ...");
      let timeout = 50 * 1.35 ** this.ws_failcount;
      this.add_wait_for("connect_retry");
      setTimeout(() => {
        this.connect();
        this.del_wait_for("connect_retry");
      }, timeout);
      return;
    }
  }

  on_ws_error(evt: any) {
    console.error("TxticketApi: Websocket error", evt);
  }

  on_ws_message(event: any) {
    if (typeof event.data != "string") {
      console.error(
        "TxticketApi: got a non-string message over the web socket"
      );
      return;
    }

    var message;
    try {
      message = JSON.parse(event.data);
    } catch (e) {
      console.error(
        "TxticketApi: got non-JSON message over the web socket: " + e
      );
      return;
    }

    if ("error" in message) {
      console.error("TxticketApi: got error message: ", message.error);
      if (this.on_error) this.on_error(message);
      // else toast.error("Error: <br>" + message.error); //emmy commented this out
      return;
    }

    let { method, ...data } = message;

    if (!method) {
      console.error(
        "TxticketApi: websocket message has no method: " + event.data
      );
      return;
    }

    console.log("TxticketApi: ws >>>>", method, data);
    this.dispatch_message(method, data);
  }

  is_sending_method(m: string) {
    for (const msg of this.send_queue) {
      if ("method" in msg && msg.method === m) return true;
    }
    return false;
  }

  add_wait_for(s: string) {
    if (this.waiting_for[s] === true) {
      console.warn("TxticketApi: already waiting for " + s);
      return;
    }
    this.waiting_for[s] = true;
    console.log(
      "* TxticketApi: waiting for " + Object.keys(this.waiting_for).join(", ")
    );
    this.notify_wait();
  }

  del_wait_for(s: string) {
    if (!this.waiting_for[s]) {
      console.warn("TxticketApi: not waiting for " + s);
      return;
    }
    delete this.waiting_for[s];
    // FIXME update_spinner_visibility();
    if (Object.keys(this.waiting_for).length > 0)
      console.log(
        "* TxticketApi: waiting for " + Object.keys(this.waiting_for).join(", ")
      );
    else console.log("* TxticketApi: ready");

    this.notify_wait();
  }

  notify_wait() {
    if (!this.on_waiting) return;

    let w = Object.keys(this.waiting_for);

    if (this.delayed_wait_notification === null) {
      if (w.length > 0) {
        this.delayed_wait_notification = setTimeout(() => {
          this.delayed_wait_notification = null;
          let w = Object.keys(this.waiting_for);
          if (this.on_waiting) this.on_waiting(w);
        }, 500);
      } else {
        this.on_waiting(w);
      }
    } else {
      if (w.length === 0) {
        clearTimeout(this.delayed_wait_notification);
        this.delayed_wait_notification = null;
        this.on_waiting(w);
      }
    }
  }

  load_stations() {
    this.send({ method: "list_stations" });
    this.add_wait_for("stationlist");
  }

  load_shopping_basket() {
    this.send({ method: "get_shopping_basket" });
    this.add_wait_for("shopping_basket");
  }

  arrays_are_equal(a: any[], b: any[]) {
    if (!a && !b) return true;
    if (!a || !b) return false;
    return (
      a.length === b.length && a.every((element, index) => element === b[index])
    );
  }

  check_date(s: string) {
    var date_regex = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;
    var m = s.match(date_regex);
    if (m === null) return false;
    var year = parseInt(m[1], 10);
    var month = parseInt(m[2], 10);
    var day = parseInt(m[3], 10);
    if (year < 2000 || month < 1 || month > 12 || day < 1 || day > 31)
      return false;
    if (month === 4 || month === 6 || month === 9 || month === 11)
      return day <= 30;
    if (month !== 2) return true;
    if (day <= 28) return true;
    if (day > 29) return false;
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }

  get_seating_scheme(schemeid: number) {
    this.send({ method: "get_scheme", schemeid: schemeid });
    this.add_wait_for("scheme" + schemeid);
  }

  dispatch_message(method: IDispatchType, data: any) {
    let dispatch_map: IDispatchMethods = {
      add_ticket: (x) => {
        this.on_message_add_ticket(x);
      },
      compute_route: (x) => {
        this.on_message_compute_route(x);
      },
      del_ticket: (x) => {
        this.on_message_del_ticket(x);
      },
      last_purchase: (x) => {
        this.on_message_last_purchase(x);
      },
      payment: (x) => {
        this.on_message_payment(x);
      },
      pricelist: (x) => {
        this.on_message_pricelist(x);
      },
      purchase: (x) => {
        this.on_message_purchase(x);
      },
      reservation: (x) => {
        this.on_message_reservation(x);
      },
      reservationlist: (x) => {
        this.on_message_reservationlist(x);
      },
      scheme: (x) => {
        this.on_message_scheme(x);
      },
      sessionstate: (x) => {
        this.on_message_sessionState(x);
      },
      session_expired: (x) => {
        this.on_message_session_expired(x);
      },
      stationlist: (x) => {
        this.on_message_stationlist(x);
      },
      shopping_basket: (x) => {
        this.on_message_shopping_basket(x);
      },
      trainrun: (x) => {
        this.on_message_trainrun(x);
      },
    };
    let handler = dispatch_map[method];
    if (handler) {
      handler(data);
    } else
      console.error(
        "TxticketApi: websocket message with unknown method " + method
      );
  }

  on_message_add_ticket(data: any) {
    for (let r of data.params.route.leg) {
      this.get_trainrun(r.lxid, r.lxdate);
    }

    let continuation = this.on_add_ticket;
    this.on_add_ticket = null;
    if (continuation) continuation(data);
  }

  on_message_compute_route(data: IComputeRouteResponse) {
    let id = data.id;
    let route_data = data.data;
    let lxaux = data.lxaux;

    if (id !== this.last_route_id) {
      console.error("TxticketApi: received unexpected route id", data);
      return;
    }

    if (!("routes" in route_data) || route_data.routes.length < 1) {
      console.error("TxticketApi: got bad routing data: ", route_data);
      this.routing_finished();
      return;
    }

    for (let lxid in lxaux) {
      let lx = lxaux[lxid];
      this.line_service_cache[lxid] = lx;
    }

    let continuation = this.compute_route_continuation;
    if (continuation !== null) {
      this.compute_route_continuation = null;
      continuation(route_data.routes);
    }
  }

  on_message_del_ticket(data: any) {
    let continuation = this.on_del_ticket;
    this.on_del_ticket = null;
    if (continuation) continuation(data);
  }

  on_message_last_purchase(data: any) {
    let continuation = this.on_last_purchase;
    this.on_last_purchase = null;
    if (continuation) {
      continuation(data.data);
    }
  }

  on_message_payment(data: IStartPaymentResponse) {
    let continuation = this.on_start_payment;
    this.on_start_payment = null;
    if (continuation) {
      if (data.jsondata.text) data.text = data.jsondata.text;
      continuation(data);
    }
  }

  on_message_pricelist(data: IOnMessagePriceListResponse) {
    this.del_wait_for("product_prices");

    // let id = data.id;
    let plist = data.data;
    console.log("TxticketApi: pricelist:", plist);

    let continuation = this.on_products_ready;
    this.on_products_ready = null;
    if (continuation) {
      this.produce_price = plist;
      continuation(plist);
    }
  }

  on_message_purchase(data: IPurchaseResponse) {
    this.del_wait_for("purchase");

    let continuation = this.on_purchase;
    this.on_purchase = null;
    if (continuation) continuation(data);
  }

  on_message_reservation(data: any) {
    let rv = data.data;
    let rvnum = rv.rvnum;
    if (rvnum < 1) return;
    rvnum -= 1;
    this.own_reservationlist[rvnum] = rv;
  }

  on_message_reservationlist(data: any) {
    this.own_reservationlist = data.data;

    let seating_schemes_missing = this.seating_schemes_missing();

    if (seating_schemes_missing.length === 0) {
      let continuation = this.on_reservationlist_ready;
      this.on_reservationlist_ready = null;
      if (continuation) continuation(this.own_reservationlist);
      return;
    }

    for (let schemeid of seating_schemes_missing) {
      this.get_seating_scheme(schemeid as number);
    }
  }

  on_message_scheme(data: any) {
    let s = data.data;
    let schemeid = s.schemeid;
    let scheme_data = s.scheme_data;
    this.del_wait_for("scheme" + schemeid);
    this.seating_scheme[schemeid] = scheme_data;

    let continuation = this.on_reservationlist_ready;
    if (continuation) {
      let schemes = this.seating_schemes_missing();
      if (schemes.length === 0) {
        this.on_reservationlist_ready = null;
        if (continuation) continuation(this.own_reservationlist);
      }
    }
  }

  on_message_sessionState(data: any) {
    console.log("TxticketApi: new session state " + JSON.stringify(data.state));
    if (data.state === "PURCHASED" && this.sessionState == null) {
      // FIXME: a previous purchase has not been saved yet
      /* remove_session_cookies();
            location.reload(); */
    }
    // handle failed payment verification
    if (this.sessionState === "PAYING_ALLEGED" && data.state === "SHOPPING") {
      // we won't get any purchase and shopping basket messages
      this.del_wait_for("purchase");
      this.del_wait_for("shopping_basket");
    }
    this.sessionState = data.state;
    if (this.sessionState === "PAYING") {
      this.last_pmtref = data.pmtref;
      this.last_payment_data = data;
      console.log("session last_payment_data", data);

      let continuation = this.on_payment_changed;
      if (continuation) {
        this.on_payment_changed = null;
        if (data) continuation(data);
      }
    }

    if (this.sessionState === "PAYING") {
      this.last_pmtref = data.pmtref;
      this.last_payment_data = data;
      console.log("session last_payment_data", data);

      let continuation = this.on_payment_changed;
      if (continuation) {
        this.on_payment_changed = null;
        if (data) continuation(data);
      }
    }

    this.call_on_init();
  }

  on_message_session_expired(data: any) {
    console.warn("TxticketApi: session expired");
    if (this.ws) this.ws.close();

    if (this.on_session_expired) this.on_session_expired();
  }

  on_message_stationlist(data: any) {
    this.del_wait_for("stationlist");
    let slist = data.data;
    console.log("TxticketApi: received " + slist.length + " stations");

    let something_changed = false;
    for (var i = 0; i < slist.length; i++) {
      let stid = slist[i][0];
      let station_name = slist[i][1];
      if (this.station_name_cache[stid] === station_name) continue;
      this.station_name_cache[stid] = station_name;
      something_changed = true;
    }
    this.station_list_initialized = true;
    this.call_on_init();

    if (something_changed && this.on_stations_changed)
      this.on_stations_changed();
    if (this.notify_shopping_basket_on_station_list) {
      console.log("TxticketApi: sending delayed shopping basket notification");
      this.notify_shopping_basket_on_station_list = false;
      if (this.on_shopping_basket_changed) this.on_shopping_basket_changed();
    }
  }

  on_message_shopping_basket(data: IShoppingBasketResponse) {
    console.log("TxticketApi: got shopping basket", data);
    this.del_wait_for("shopping_basket");
    this.shopping_basket = data.data;

    for (let stid in data.staux) {
      let st = data.staux[stid];
      this.station_name_cache[parseInt(stid)] = st.name;
    }

    for (let lxid in data.lxaux) {
      let lx = data.lxaux[lxid];
      this.line_service_cache[lxid] = lx;
    }

    if (data.stidorg) this.current_stid_origin = data.stidorg;
    if (data.stiddst) this.current_stid_destination = data.stiddst;
    if (data.input_date) this.current_input_date = data.input_date;

    this.call_on_init();

    this.check_reservations_ready();

    if (this.station_list_initialized) {
      if (this.on_shopping_basket_changed) this.on_shopping_basket_changed();
    } else {
      console.log(
        "TxticketApi: delaying shopping basket notification after station list"
      );
      this.notify_shopping_basket_on_station_list = true;
    }
  }

  on_message_trainrun(data: any) {
    this.reload_shopping_basket();
    if (this.on_trainrun_reservation_change)
      this.on_trainrun_reservation_change(data.data);
  }

  seating_schemes_missing() {
    let missing: { [key: string]: any } = {};
    for (let ri of this.own_reservationlist) {
      let schemeid = ri.scheme;
      if (!(schemeid in this.seating_scheme)) {
        missing[schemeid] = schemeid;
      }
    }
    return Object.values(missing);
  }

  call_on_init() {
    if (this.on_init_sent) return;
    if (this.sessionState === null) return;
    if (this.shopping_basket === null) return;
    if (!this.station_list_initialized) return;

    // Reset the fail count
    this.ws_failcount = 0;

    this.on_init_sent = true;
    if (!this.on_init) return;
    this.on_init();
  }
}

const ApiInstance = new TxticketApi("wss://nrc.txware.com/sessionws");

export default ApiInstance;
