import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import PurchaseHistoryNavigation from "./PurchaseHistoryNavigation/PurchaseHistoryNavigation";
// import ProfileDropdown from "./ProfileDropdown/ProfileDropdown";
import InformationNavigation from "./InformationNavigation/InformationNavigation";
import ShoppingCartNavigation from "./ShoppingCartNavigation/ShoppingCartNavigation";
import HomeNavigation from "./HomeNavigation/HomeNavigation";

//import images
import logosm from "../../assets/images/logo-sm.png";
import logodark from "../../assets/images/logo-dark.png";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import ApiInstance from "../../helpers/txticket_api";
import {
  BookingContext,
  BookingContextType,
} from "../../context/BookingContext";
import { toast } from "react-toastify";
import ApiSessionInstance from "../../helpers/session_api";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState("d-none");

  const { updateShoppingCartBasket, shoppingCartBasket } = React.useContext(
    GlobalContext,
  ) as GlobalContextType;
  const { setIsBooking, setIsTripSelection } = React.useContext(
    BookingContext,
  ) as BookingContextType;

  const isSmallDevice= useMediaQuery({
    query:"(max-width: 380px)"
  })

  const resetBooking = () => {
    console.log("Current shoppingCartBasket length:", shoppingCartBasket.length); // Debugging log
    if (shoppingCartBasket?.length === 3) {
      console.log("You clicked the logo with 3 items in the cart."); // Debugging log
      toast.warning("You can only buy three tickets at once. Please click on 'Shopping Cart' where you can either proceed with payment, or remove tickets.", {
        autoClose: 15000,
      });
    } else if (shoppingCartBasket.length < 3) {
      console.log("You clicked the logo with less than 3 items in the cart."); // Debugging log
      toast.dismiss(); // Ensure this is not called before the toast is shown
      setTimeout(() => {
        navigate("/");
        setIsBooking(false);
        setIsTripSelection(true);
        ApiSessionInstance.clearAwaitingApiResponse();
        ApiSessionInstance.clearConfirmBooking();
      }, 100); // Delay navigation slightly
    }
  };

  useEffect(() => {
    const windowScroll = () => {
      const pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 20 ||
        document.documentElement.scrollTop >= 20
          ? pageTopbar.classList.add("topbar-shadow")
          : pageTopbar.classList.remove("topbar-shadow");
      }
    };
    window.addEventListener("scroll", windowScroll);
    return () => {
      window.removeEventListener("scroll", windowScroll);
    };
  }, []);

  useEffect(() => {
    if (!shoppingCartBasket.length) {
      ApiInstance.on_shopping_basket_changed = () => {
        let shoppingBasket = ApiInstance.get_shopping_basket();
        console.log("shoppingBasket", shoppingBasket);
        if (shoppingBasket) updateShoppingCartBasket(shoppingBasket);
      };
    }
  }, [shoppingCartBasket.length, updateShoppingCartBasket]);

  const windowScroll = () => {
    const pageTopbar = document.getElementById("page-topbar");
    if (pageTopbar) {
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        pageTopbar.classList.add("topbar-shadow");
      } else {
        pageTopbar.classList.remove("topbar-shadow");
      }
    }
  };

  if (typeof document !== "undefined") {
    document.onscroll = function () {
      windowScroll();
    };
  }

  const toggleMenuBtn = () => {
    setToggle((prev) => (prev !== "d-flex" ? "d-flex" : "d-none"));
  };

  const handleClick = () => {
    navigate("/shopping-cart");
    setToggle("d-none");
  };

  const smClassName = "bg-transparent w-100 text-start";
  const smLogoDesign =isSmallDevice? "logo-sm-d" :""

  return (
    <React.Fragment>
      <header id="page-topbar" className="d-print-none">
        <div className="layout-width">
          <Container fluid className="navbar-header" style={{marginTop: isSmallDevice? "-15px" : ""}}>
            <div
              className="d-flex justify-content-between flex-grow-1 flex-md-grow-0"
              style={{ paddingTop:isSmallDevice? "5px" : "20px", marginBottom:isSmallDevice? "-25px" : "" }}
            >
              <div className="navbar-brand-box horizontal-logo d-flex px-1">
                <div className="logo logo-dark" onClick={resetBooking}>
                  <span className="logo-sm" style={{ marginBottom: "-2px" }}>
                    <img src={logosm} className={`${smLogoDesign}`} alt="" height="40" />
                  </span>
                  <span className="logo-lg">
                    <img src={logodark} alt="" height="21" />
                  </span>
                </div>
              </div>

              <h3
                className="header-item nrc-name d-flex d-md-none text-primary "
                style={{
                  fontSize:isSmallDevice? "40px" : "60px",
                  fontWeight: "500",
                  marginLeft: "30px",
                  paddingBottom: "-10px",
                }}
              >
                NRC
              </h3>

              <div className="d-flex">
                <Button
                  variant="link"
                  size="sm"
                  type="button"
                  className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger text-primary d-flex d-md-none"
                  id="topnav-hamburger-icon"
                  onClick={handleClick}
                >
                  {shoppingCartBasket.length < 1 ? null : (
                    <span className="me-1 position-relative py-2 px-3">
                      <sup
                        className={`badge badge-primary position-absolute end-0 ${
                          shoppingCartBasket.length > 9 ? "px-1" : ""
                        }`}
                        style={{
                          border: "1px solid #00f600",
                          backgroundColor: "none",
                        }}
                      >
                        {shoppingCartBasket.length}
                      </sup>
                      {/* <i className="bi bi-cart-check-fill text-primary"></i> */}
                    </span>
                  )}
                </Button>

                <Button
                  variant="link"
                  size="sm"
                  type="button"
                  className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger text-primary d-flex d-md-none"
                  id="topnav-hamburger-icon"
                  onClick={toggleMenuBtn}
                >
                  <span className="hamburger-icon">
                    <span className="bg-white"></span>
                    <span className="bg-white"></span>
                    <span className="bg-white"></span>
                  </span>
                </Button>
              </div>
            </div>

            {/* Small screen */}
            <div
              className={`${toggle} d-md-none align-items-center position-absolute top-50 bg-dark border border-primary rounded-4 w-75`}
            >
              <div className="position-relative flex-column align-items-center w-100">
                <i
                  onClick={toggleMenuBtn}
                  className="bi bi-x-lg fs-12 ms-1 text-white position-absolute top-0 end-0 p-3 z-1"
                ></i>
                {/* Home Navigation */}
                <HomeNavigation
                  className={smClassName}
                  onClick={toggleMenuBtn}
                />

                {/* My Cart Dropdown */}
                <ShoppingCartNavigation
                  className={smClassName}
                  onClick={toggleMenuBtn}
                />

                {/* InformationNavigation */}
                <InformationNavigation
                  className={smClassName}
                  onClick={toggleMenuBtn}
                />

                {/* PurchaseHistoryNavigation */}
                <PurchaseHistoryNavigation
                  className={smClassName}
                  onClick={toggleMenuBtn}
                />

                {/* ProfileDropdown */}
                {/* <ProfileDropdown
                  className={smClassName}
                  onClick={toggleMenuBtn}
                /> */}
              </div>
            </div>

            {/* Tablet - Desktop screen */}
            <div className="d-none d-md-flex align-items-center">
              {/* Home Navigation */}
              <HomeNavigation />

              {/* My Cart Dropdown */}
              <ShoppingCartNavigation />

              {/* InformationNavigation */}
              <InformationNavigation />

              {/* PurchaseHistoryNavigation */}
              <PurchaseHistoryNavigation />

              {/* ProfileDropdown */}
              {/* <ProfileDropdown /> */}
            </div>
          </Container>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
