import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import ShoppingCart from "../Pages/ShoppingCart/ShoppingCart";
import Buy from "../Pages/Buy/Buy";
import PurchaseWithoutRegistering from "../Pages/PurchaseWithoutRegistering/PurchaseWithoutRegistering";
import Buying from "../Pages/Buying/Buying";
import BuyingProcessedLoading from "../Pages/BuyingProcessedLoading/BuyingProcessedLoading";
import BuyingSuccess from "../Pages/BuyingSuccess/BuyingSuccess";
import PurchaseHistory from "../Pages/PurchaseHistory/PurchaseHistory";
import Information from "../Pages/Information/Information";
import PurchasedTicket from "../Pages/PurchasedTicket/PurchasedTicket";
import PurchasedTicketLocked from "../Pages/PurchasedTicketLock/PurchasedTicketLocked";

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          <Route path="/" element={<Home />} />
          <Route
            path="/continue-without-registration"
            element={<PurchaseWithoutRegistering />}
          />
          <Route path="/shopping-cart" element={<ShoppingCart />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/buying" element={<Buying />} />
          <Route
            path="/buying-processed-loading"
            element={<BuyingProcessedLoading />}
          />
          <Route path="/buying-success" element={<BuyingSuccess />} />
          <Route path="/purchase-history" element={<PurchaseHistory />} />
          <Route path="/information" element={<Information />} />
          <Route
            path="/purchased-ticket/:purchaseId"
            element={<PurchasedTicket />}
          />
          <Route
            path="/purchased-ticket/:purchaseId/locked"
            element={<PurchasedTicketLocked />}
          />
          <Route
            path="/purchase-history/verified"
            element={<Home />}
          />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
